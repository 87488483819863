export const VM = [
  {
    type: 'item',
    text: 'Inspect Links',
    disable: true,
  },
  {
    type: 'item',
    text: 'Enter a Universe as Maker',
    disable: true,
  },
  {
    type: 'item',
    text: 'Start Automatic Portal Jump Fee Distribution',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Upgrade Multiverse',
    disable: true
  }
];
const Connectors = [
  {
    type: 'item',
    disable: true,
    text: 'Upgrade Loot Connector',
    hotkey: 'Ctrl+1',
  },
  {
    type: 'item',
    disable: true,
    text: 'Import PUNKS Connector',
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    text: 'Import ***GUIN Connector',
    disable: true,
  },
  {
    type: 'item',    
    text: 'Import A*ES Connector',
    disable: true,
  }
];
export default { VM, Connectors };
