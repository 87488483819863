import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import styled from 'styled-components';
// add child div to capture mouse event when not focused
import {
 trailerhost,youtubeurlA,YOUTUBEMODE
} from 'lib/lib.js';
function Movie({ onClose, isFocus }) {
  const [dappState, setDappState] = useGlobal();

  const contract = dappState.contract;
  const address = dappState.address;

  return (
    <Div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <div className='vidDiv'>
   

        {(!YOUTUBEMODE) && (
      <video className='thevid' src={trailerhost+"trailerA.mp4"}
      autoPlay loop  playsInline controls preload="auto" >
      </video>
          )}
          {(YOUTUBEMODE) && (

<iframe id="ytplayer" className='youtubevid' type="text/html" 
src={youtubeurlA}
frameborder="0" allowfullscreen></iframe>
          )}

      </div>

    </Div>
  );
}

const Div = styled.div`
width: '100%';
height: '100%';
position: 'relative';


.youtubevid{
  width: 100%;
  height: 100%;
}

.thevid{
      width: 100%;
  height: 100%;
  max-height: 100%;
}

.vidDiv{    width: 100%;
  max-height: 100%;
  height: 100%;
  background: black;
}

`;

export default Movie;
