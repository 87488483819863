import React, { setGlobal ,useGlobal} from 'reactn';

import ReactDOM from 'react-dom';


import './index.css';
import 'assets/clear.css';
import 'assets/font.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { reducers } from './reducers';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';



import Web3Modal from "web3modal";
import { ethers } from 'ethers'
import MultiverseVirtualMachine from 'artifacts/contracts/MultiverseVirtualMachine.sol/MultiverseVirtualMachine.json'
import XenoInfinity from 'artifacts/contracts/XenoInfinity.sol/XenoInfinity.json'
import MysteryNFT from 'artifacts/contracts/MysteryNFT.sol/MysteryNFT.json';
import NFTWrapperTwo from 'artifacts/contracts/NFTWrapperTwo.sol/NFTWrapperTwo.json';
import {CONTRACT_ADDR,PLAYER_CONTRACT_ADDR,MVM_CONTRACT_ADDR, MYSTERY_NFT_CONTRACT_ADDR, NFTWRAPPERTWO_CONTRACT_ADDR} from 'lib/lib.js'
import './index.css';

const store = createStore(reducers, compose(applyMiddleware(thunk)));



//------------------------------------------wallet setup

const contractAddress = CONTRACT_ADDR

const providerOptions = {
  /* See Provider Options Section */
};



var defaultDappState = {
  address:window.ethereum?window.ethereum.selectedAddress:null,
  provider:null,
  contract:null,
  extradata:"",
  chainId:null,
  web3Modal:new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions // required
  }),
  extradata2:1,
  externalstatread:false,
  hastoken:false
};


console.log("setting default app state");
console.log(defaultDappState);

//set provider if exists
if(window.ethereum!=null){
  console.log("metamask detected");
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  const contract =
   new ethers.Contract(contractAddress, 
    XenoInfinity.abi, provider)
 
  //player/mvm----------------------------------------------------------------------

  const playercontractin = new ethers.Contract(
    PLAYER_CONTRACT_ADDR,
    XenoInfinity.abi,
    provider
  );

  const mvmcontractin = new ethers.Contract(
    MVM_CONTRACT_ADDR,
    MultiverseVirtualMachine.abi,
    provider
  );
  const mynftcontractin = new ethers.Contract(
    MYSTERY_NFT_CONTRACT_ADDR,
    MysteryNFT.abi,
    signer
  )

  const nftwrappertwocontractin = new ethers.Contract(
    NFTWRAPPERTWO_CONTRACT_ADDR,
    NFTWrapperTwo.abi,
    signer
  )

  defaultDappState.mvmcontract=mvmcontractin;
  defaultDappState.playercontract=playercontractin;
  defaultDappState.mynftcontract=mynftcontractin;
  defaultDappState.nftwtwocontract=nftwrappertwocontractin;

  //---------------------------------------------------------------------
 
  console.log("setup contract and provider")
  defaultDappState.provider=provider;
  defaultDappState.contract=contract;
}

console.log("default app state",defaultDappState);
setGlobal(defaultDappState);


// Note that this event is emitted on page load.
// If the array of accounts is non-empty, you're already
// connected.


//------------------------------------------wallet setup


ReactDOM.render(
    <App />
  , document.getElementById('root'));

serviceWorker.register();
