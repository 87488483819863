import React, { useRef, memo } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import styled from 'styled-components';

import { useElementResize } from 'hooks';
import HeaderButtons from './HeaderButtons';

function Windows({
  apps,
  onMouseDown,
  onClose,
  onMinimize,
  onMaximize,
  focusedAppId, onClickMenuItem
}) {












  return (
    <div style={{ position: 'relative', zIndex: 0 }}>
      {apps.map(app=> (
        <StyledWindow
          show={!app.minimized}
          key={app.id} 
          id={app.id}
          ExtraData={app.ExtraData}
          onMouseDown={onMouseDown}
          onMouseUpClose={onClose}
          onMouseUpMinimize={onMinimize}
          onMouseUpMaximize={onMaximize}
          onClickMenuItem={onClickMenuItem}
          isFocus={focusedAppId === app.id} // for styledWindow
          {...app}
        />
      ))}
    </div>
  );
}



/* 
return (
  <div style={{ position: 'relative', zIndex: 0 }}>
    {apps.map(app => (
      <StyledWindow 
        show={!app.minimized}
        key={app.id}
        id={app.id}
        ExtraData={app.ExtraData}
        onMouseDown={onMouseDown}
        onMouseUpClose={onClose}
        onMouseUpMinimize={onMinimize}
        onMouseUpMaximize={onMaximize}
        onClickMenuItem={onClickMenuItem}
        isFocus={focusedAppId === app.id} // for styledWindow
        {...app}
      />
    ))}
  </div>
);
} */

const Window = memo(function({
  injectProps,
  id,
  onMouseDown,
  onMouseUpClose,
  onMouseUpMinimize,
  onMouseUpMaximize,
  header,
  defaultSize,
  defaultOffset,
  resizable,
  maximized,
  component,
  zIndex,
  isFocus,
  className,
  onClickMenuItem,
  ExtraData
}) {
  function _onMouseDown() {
    onMouseDown(id);
  }
  function _onMouseUpClose() {
    onMouseUpClose(id);
  }
  function _onMouseUpMinimize() {
    onMouseUpMinimize(id);
  }
  function _onMouseUpMaximize() {
    if (resizable) onMouseUpMaximize(id);
  }
  const dragRef = useRef(null);
  const ref = useRef(null);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { offset, size } = useElementResize(ref, {
    dragRef,
    defaultOffset,
    defaultSize,
    boundary: {
      top: 1,
      right: windowWidth - 1,
      bottom: windowHeight - 31,
      left: 1,
    },
    resizable,
    resizeThreshold: 10,
  });
  let width, height, x, y;
  if (maximized) {
    width = windowWidth + 6;
    height = windowHeight - 24;
    x = -3;
    y = -3;

    x = -3;
    y = 29;


  } else {
    width = size.width;
    height = size.height;
    x = offset.x;
    y = offset.y;
  }
  return (
    <div
      className={className}
      ref={ref}
      onMouseDown={_onMouseDown}
      style={{
        transform: `translate(${x}px,${y}px)`,
        width: width ? `${width}px` : 'auto',
        height: height ? `${height}px` : 'auto',
        zIndex,
      }}
    >
      <div className="header__bg" />
      <header className="app__header" ref={dragRef}>
        <img
          src={header.icon}
          alt={header.title}
          className="app__header__icon"
        />
        <div className="app__header__title">{header.title}</div>
        <HeaderButtons
          buttons={header.buttons}
          onMaximize={_onMouseUpMaximize}
          onMinimize={_onMouseUpMinimize}
          onClose={_onMouseUpClose}
          maximized={maximized}
          resizable={resizable}
          isFocus={isFocus}
        />
      </header>
      <div className="app__content">
        {component({
          onClose: _onMouseUpClose,
          onMinimize: _onMouseUpMinimize,
          onClickMenuItem:onClickMenuItem,
          isFocus,
          ExtraData:ExtraData,
          ...injectProps,
        })}
      </div>
    </div>
  );
});

// const StyledWindow = styled(Window)`
//   display: ${({ show }) => (show ? 'flex' : 'none')};
//   position: absolute;
//   padding: 3px;
//   padding: ${({ header }) => (header.invisible ? 0 : 3)}px;
//   background-color: ${({ isFocus }) => (isFocus ? '#202127' : '#2e2f38')};
//   flex-direction: column;
//   border-top-left-radius: 0px;
//   border-top-right-radius: 0px;
//   .header__bg {
//     background: ${({ isFocus }) =>
//     isFocus
//     ? 'linear-gradient(90deg, rgba(255,20,151,1) 0%, rgba(121,9,109,1) 47%, rgba(139,235,255,1) 100%)'
//     : 'linear-gradient(90deg, rgba(255,20,151,1) 0%, rgba(121,9,109,1) 47%, rgba(139,235,255,1) 100%)'};
const StyledWindow = styled(Window)`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  padding: 3px;
  padding: ${({ header }) => (header.invisible ? 0 : 3)}px;
  background-color: ${({ isFocus }) => (isFocus ? '#202127' : '#2e2f38')};
  flex-direction: column;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  .header__bg {
    background: ${({ isFocus }) =>
    isFocus
    // ? 'lightgray'
    // : 'lightgray'};
    //     ? 'linear-gradient(90deg, rgba(255,20,151,1) 0%, rgba(121,9,109,1) 47%, rgba(139,235,255,1) 100%)'
    // :     'linear-gradient(90deg, rgba(255,20,151,1) 0%, rgba(121,9,109,1) 47%, rgba(139,235,255,1) 100%)'};
         ? '#111114'
     : '#111114'};
     position: absolute;
    left: 0;
    top: 3px;
    right: 0;
    height: 25px;
    opacity: ${({ isFocus }) => (isFocus ? 1 : 0.5)};
    pointer-events: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    overflow: hidden;
  }
  .header__bg:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    opacity: ${({ isFocus }) => (isFocus ? 1 : 1)};
    background: #202127;
    top: 0;
    bottom: 0;
    width: 3px;
  }
  .header__bg:after {
    content: '';
    opacity: ${({ isFocus }) => (isFocus ? 1 : 0.4)};
    display: block;
    position: absolute;
    right: 0;
    background: #202127;
    top: 0;
    bottom: 0;
    width: 3px;
  }
  .app__header {
    display: ${({ header }) => (header.invisible ? 'none' : 'flex')};
    height: 25px;
    line-height: 25px;
    font-weight: 700;
   
    text-shadow: 1px 1px #000;
    color: white;
    position: absolute;
    left: 3px;
    right: 3px;
    align-items: center;
    padding-left: 4px;
  }
  .app__header__icon {
    pointer-events: none;
    width: 15px;
    height: 15px;
    margin-left: 1px;
    margin-right: 5px;
  }
  .app__header__title {
    flex: 1;
    pointer-events: none;
    padding-right: 5px;
    letter-spacing: 0.5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .app__content {
    flex: 1;
    position: relative;
    margin-top: 25px;
    height: calc(100% - 25px);
  }
`;

export default Windows;
