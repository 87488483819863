import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import styled from 'styled-components';
// add child div to capture mouse event when not focused

function EasterEgg({ onClose, isFocus }) {
  const [dappState, setDappState] = useGlobal();

  const contract = dappState.contract;
  const address = dappState.address;

  const [balance, setBalance] = useState(0);
  const [queryReady, setQueryReady] = useState(false);
  const [timer, SetTimer] = useState(80);

  async function fetchUserBalance(address) {
    if (typeof window.ethereum !== 'undefined'&&address!=null&&address!='') {
      console.log('fetching balance for user: ', address);

      try {
        const data = await contract.balanceOf(address);
        console.log('user balance: ', data);
        setBalance(data);
        return data;
      } catch (err) {
        console.log('user balance Error: ', err);
      }
    } else {
      console.log('ethereum api not detected');
      return '';
    }
  }

  useEffect(() => {
    setQueryReady(false);

    console.log('use effect triggered');

    async function fetchData() {
      console.log('fetching data for token');

      let bal = await fetchUserBalance(address);
      setBalance(bal);
      setQueryReady(true);
    }

    fetchData();
  }, [address]);

  let  timerId=0;

  function countdowntimer(){

    let timerin=timer;
    if(timerin<2){
      timerin=0;
    }else{
      timerin--;
    }

    SetTimer(timerin);
    clearInterval(timerId);
  }

  useEffect(() => {

     if(timer>0){
       
     timerId = setInterval(() => countdowntimer(), 1000);
    }

    });

  return (
    <Div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
   
   {!queryReady &&<div className='box'>Querying the blockchain...</div>}
{/* 
   {queryReady  && balance==0&& <div className='box'>Playable for Token Owners {timer}</div>}
 */}
  
  {queryReady  && balance==0&&timer>0&& <div className='box'>Remaining Time {timer} Seconds, Full Version available to Token Owners<br/> Movement: ↑ ↓ ← →, Attack: X, Open Door: Space</div>}

  {queryReady  && balance==0&&timer==0&& <div className='box nonplaybox'>Thanks for playing <br/>Get Your Multiverse VM Simulation <br/> To Unlock Full Game</div>}
   {/* 
  {queryReady  && ((balance==0&& timer>0) 
    || (balance>0))
  && <iframe
        src="html_wolf3d/index.html"
        frameBorder="0"
        title="Wolf 3D (Earth sim)"
        style={{
          display: 'block',
          width: '100%',
          height: balance==0?'90%':'100%',
          backgroundColor: 'rgb(192,192,192)',
        }}
      />}
 */}
    
      {!isFocus && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        />
      )}  
    </Div>
  );
}
// background: url('html_wolf3d/art/title.png');

const Div = styled.div`
width: '100%';
height: '100%';
position: 'relative';

.box{    
 
  width:100%!important;
  height: 10%!important;
  display: flex!important;
  justify-content: space-around!important;
  align-items: center!important;    
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;    
  color: white;
  font-size: 12px;
  text-align: center;
}

.nonplaybox{    
  height: 100%!important;
  font-size: 25px;
}


`;

export default EasterEgg;
