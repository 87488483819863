import React, {
  useReducer,
  useRef,
  useCallback,
  useState,
  useEffect,
  Fragment,
} from 'react';

/* import {  useGlobal, setGlobal } from 'reactn'; */

import styled, { keyframes } from 'styled-components';
import useMouse from 'react-use/lib/useMouse';
/* import ga from 'react-ga'; */
import { useDispatch, useSelector } from 'react-redux';

import { useGlobal } from 'reactn';

import { INTROTIME } from 'lib/lib.js';

import '../assets/backgrounds/bgstyle.css';
import bgimg from '../assets/backgrounds/backgroundgif.gif';

import bgimgc from '../assets/backgrounds/backgroundtest.png';

//import bgimgd from '../assets/backgrounds/backgroundtest2.gif';
import bgimgd from '../assets/backgrounds/bg_mint.gif';

import labgif from '../assets/backgrounds/labgif.gif';
import labselect from '../assets/backgrounds/labselect.png';
import metamask from '../assets/backgrounds/metamask.gif';
/* import metamaskicon from '../assets/backgrounds/metamaskicon.gif'; */
import metamaskicon from '../assets/backgrounds/metamaskpixel.gif';
import metamaskselect from '../assets/backgrounds/metamaskselect.png';
import arcade from '../assets/backgrounds/arcade.gif';
import arcadeselect from '../assets/backgrounds/arcadeselect.png';
import radio from '../assets/backgrounds/radiogif.gif';
import radioselect from '../assets/backgrounds/radio_png.png';
import portal from '../assets/backgrounds/portal.gif';
import portalselect from '../assets/backgrounds/portalselect.png';
import cinema from '../assets/backgrounds/cinema.gif';
import cinemaselect from '../assets/backgrounds/cinemaselect.png';
import infoboard from '../assets/backgrounds/infoboard.gif';
import infoboardselect from '../assets/backgrounds/infoboardselect.png';


import test from '../assets/backgrounds/outline/arcadeoutline.png';

import arcadeoutline from '../assets/backgrounds/outline/arcadeoutline.png';
import infooutline from '../assets/backgrounds/outline/infooutline.png';
import portaloutline from '../assets/backgrounds/outline/portaloutline.png';
import printeroutline from '../assets/backgrounds/outline/printeroutline.png';
import tvoutline from '../assets/backgrounds/outline/tvoutline.png';


import radiooutline from '../assets/backgrounds/outline/radiooutline.png';
import smalltvoutline  from '../assets/backgrounds/outline/smalltvoutline.png';

import deskoutline from '../assets/backgrounds/outline/deskoutlinehd.png';


import trailer from '../assets/backgrounds/trailerA.gif';


import trailerB from '../assets/backgrounds/trailer.gif';

/* 
import clickus from '../assets/backgrounds/clickus.gif';
import mint from '../assets/backgrounds/mint.gif';
 */

import clickus from '../assets/backgrounds/clickus.png';
import mint from '../assets/backgrounds/mint.png';




import {
  ADD_APP,
  DEL_APP,
  FOCUS_APP,
  MINIMIZE_APP,
  TOGGLE_MAXIMIZE_APP,
  FOCUS_ICON,
  SELECT_ICONS,
  FOCUS_DESKTOP,
  START_SELECT,
  END_SELECT,
  POWER_OFF,
  CANCEL_POWER_OFF,
  DAPP_STATE_UPDATE,
} from './constants/actions';
import { FOCUSING, POWER_STATE } from './constants';
import {
  defaultIconState,
  defaultAppState,
  appSettings,
  defaultDappState,
} from './apps';
import Modal from './Modal';
import Footer from './Footer';
import Windows from './Windows';
import Icons from './Icons';
import { DashedBox } from 'components';
import StartVideo from 'components/startingVideo/StartVideo';
import GifImg from 'components/startingVideo/GifImg';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initState = {
  apps: defaultAppState,
  nextAppID: defaultAppState.length,
  nextZIndex: defaultAppState.length,
  focusing: FOCUSING.WINDOW,
  icons: defaultIconState,
  selecting: false,
  powerState: POWER_STATE.START,
  hastoken: false
  //reference this?
  //dappState:defaultDappState
};



const reducer = (state, action = { type: '' }) => {
  /*   ga.event({
      category: 'XP interaction',
      action: action.type,
    }); */
  switch (action.type) {
    case ADD_APP:
      const app = state.apps.find(
        _app => _app.component === action.payload.component,
      );
      if (action.payload.multiInstance || !app) {
        return {
          ...state,
          apps: [
            ...state.apps,
            {
              ...action.payload,
              id: state.nextAppID,
              zIndex: state.nextZIndex,
            },
          ],
          nextAppID: state.nextAppID + 1,
          nextZIndex: state.nextZIndex + 1,
          focusing: FOCUSING.WINDOW,
        };
      }
      const apps = state.apps.map(app =>
        app.component === action.payload.component
          ? { ...app, zIndex: state.nextZIndex, minimized: false }
          : app,
      );
      return {
        ...state,
        apps,
        nextZIndex: state.nextZIndex + 1,
        focusing: FOCUSING.WINDOW,
      };
    case DEL_APP:
      if (state.focusing !== FOCUSING.WINDOW) return state;
      return {
        ...state,
        apps: state.apps.filter(app => app.id !== action.payload),
        focusing:
          state.apps.length > 1
            ? FOCUSING.WINDOW
            : state.icons.find(icon => icon.isFocus)
              ? FOCUSING.ICON
              : FOCUSING.DESKTOP,
      };
    case FOCUS_APP: {
      const apps = state.apps.map(app =>
        app.id === action.payload
          ? { ...app, zIndex: state.nextZIndex, minimized: false }
          : app,
      );
      return {
        ...state,
        apps,
        nextZIndex: state.nextZIndex + 1,
        focusing: FOCUSING.WINDOW,
      };
    }
    case MINIMIZE_APP: {
      if (state.focusing !== FOCUSING.WINDOW) return state;
      const apps = state.apps.map(app =>
        app.id === action.payload ? { ...app, minimized: true } : app,
      );
      return {
        ...state,
        apps,
        focusing: FOCUSING.WINDOW,
      };
    }
    case TOGGLE_MAXIMIZE_APP: {
      if (state.focusing !== FOCUSING.WINDOW) return state;
      const apps = state.apps.map(app =>
        app.id === action.payload ? { ...app, maximized: !app.maximized } : app,
      );
      return {
        ...state,
        apps,
        focusing: FOCUSING.WINDOW,
      };
    }
    case FOCUS_ICON: {
      const icons = state.icons.map(icon => ({
        ...icon,
        isFocus: icon.id === action.payload,
      }));
      return {
        ...state,
        focusing: FOCUSING.ICON,
        icons,
      };
    }
    case SELECT_ICONS: {
      const icons = state.icons.map(icon => ({
        ...icon,
        isFocus: action.payload.includes(icon.id),
      }));
      return {
        ...state,
        icons,
        focusing: FOCUSING.ICON,
      };
    }
    case FOCUS_DESKTOP:
      return {
        ...state,
        focusing: FOCUSING.DESKTOP,
        icons: state.icons.map(icon => ({
          ...icon,
          isFocus: false,
        })),
      };
    case START_SELECT:
      return {
        ...state,
        focusing: FOCUSING.DESKTOP,
        icons: state.icons.map(icon => ({
          ...icon,
          isFocus: false,
        })),
        selecting: action.payload,
      };
    case END_SELECT:
      return {
        ...state,
        selecting: null,
      };
    case POWER_OFF:
      return {
        ...state,
        powerState: action.payload,
      };
    // case DAPP_STATE_UPDATE://update entire state now
    //  console.log("dapp state update:"+action.payload);
    //  return {
    //    ...state,
    //    dappState: action.payload
    //  };
    case CANCEL_POWER_OFF:
      return {
        ...state,
        powerState: POWER_STATE.START,
      };
    default:
      return state;
  }
};
function WinXP() {


/* 
  const [dappState, setDappState] = useGlobal(); //entire dappstate

  
  function SetSpecialStat(stat) {
    if (focusedAppId === id) {
      dispatch({ type: MINIMIZE_APP, payload: id });
    } else {
      dispatch({ type: FOCUS_APP, payload: id });
    }
  }
 */

  const [positionX, setPositionX] = useState(36)
  const [positionY, setPositionY] = useState(31)

  const [state, dispatch] = useReducer(reducer, initState);
  const ref = useRef(null);
  const mouse = useMouse(ref);
  const focusedAppId = getFocusedAppId();
  const onFocusApp = useCallback(id => {
    dispatch({ type: FOCUS_APP, payload: id });
  }, []);
  const onMaximizeWindow = useCallback(
    id => {
      if (focusedAppId === id) {
        dispatch({ type: TOGGLE_MAXIMIZE_APP, payload: id });
      }
    },
    [focusedAppId],
  );
  const onMinimizeWindow = useCallback(
    id => {
      if (focusedAppId === id) {
        dispatch({ type: MINIMIZE_APP, payload: id });
      }
    },
    [focusedAppId],
  );
  const onCloseApp = useCallback(
    id => {
      if (focusedAppId === id) {
        dispatch({ type: DEL_APP, payload: id });
      }
    },
    [focusedAppId],
  );
  function onMouseDownFooterApp(id) {
    if (focusedAppId === id) {
      dispatch({ type: MINIMIZE_APP, payload: id });
    } else {
      dispatch({ type: FOCUS_APP, payload: id });
    }
  }
  function onMouseDownIcon(id) {
    dispatch({ type: FOCUS_ICON, payload: id });
  }


  function onDoubleClickIcon(component) {
/*  */
/*     StartApp('Search') */;
    /*     return; */
    /*  */
    const appSetting = Object.values(appSettings).find(
      setting => setting.component === component,
    );
    dispatch({ type: ADD_APP, payload: appSetting });
  }


  function PlaySound() {
    
    let audio=new Audio('/click2.mp3');
    audio.play();
  }

  function StartApp(name) {
    /*     const appSetting = Object.values(appSettings).find(
          setting => setting.component === component,
        );
     */

/* 
        let audio=new Audio('/click.mp3');


        audio.play();
 */
    const appSetting = appSettings[name];

    if (appSetting != null) {
      dispatch({ type: ADD_APP, payload: appSetting });
    }

  }

  function getFocusedAppId() {
    if (state.focusing !== FOCUSING.WINDOW) return -1;
    const focusedApp = [...state.apps]
      .sort((a, b) => b.zIndex - a.zIndex)
      .find(app => !app.minimized);
    return focusedApp ? focusedApp.id : -1;
  }
  function onMouseDownFooter() {
    dispatch({ type: FOCUS_DESKTOP });
  }
  function onClickMenuItem(o) {
    if (o === 'Internet')
      dispatch({ type: ADD_APP, payload: appSettings['Search'] });
    else if (o === 'Minesweeper')
      dispatch({ type: ADD_APP, payload: appSettings.Minesweeper });
    else if (o === 'VM Status')
      dispatch({ type: ADD_APP, payload: appSettings['VM Status'] });
    else if (o === 'Travel Log')
      dispatch({ type: ADD_APP, payload: appSettings.Notepad });
    else if (o === 'Metamask')
      dispatch({ type: ADD_APP, payload: appSettings.Metamask });
    else if (o === 'Help')
      dispatch({ type: ADD_APP, payload: appSettings.Help });
    else if (o === 'Winamp-X')
      dispatch({ type: ADD_APP, payload: appSettings.Winamp });
    else if (o === 'Paint (Earth sim)')
      dispatch({ type: ADD_APP, payload: appSettings.Paint });
    else if (o === 'Wolf 3D (Earth sim)')
      dispatch({ type: ADD_APP, payload: appSettings.EasterEgg });
    else if (o === 'Movie')
      dispatch({ type: ADD_APP, payload: appSettings.Movie });
    else if (o === 'Portal')
      dispatch({ type: ADD_APP, payload: appSettings.Portal });
    else if (o === 'VM Bot (Earth receiver)')
      dispatch({ type: ADD_APP, payload: appSettings.TwitterBot });
    else if (o === 'Multiverse VM')
      dispatch({ type: ADD_APP, payload: appSettings.Gallery });
    else if (o === 'Log Off')
      dispatch({ type: POWER_OFF, payload: POWER_STATE.LOG_OFF });
    else if (o === 'Turn Off Computer')
      dispatch({ type: POWER_OFF, payload: POWER_STATE.TURN_OFF });
    else
      dispatch({
        type: ADD_APP,
        payload: {
          ...appSettings.Error,
          injectProps: { message: 'C:\\\nApplication not found' },
        },
      });
  }
  function onMouseDownDesktop(e) {
    if (e.target === e.currentTarget)
      dispatch({
        type: START_SELECT,
        payload: { x: mouse.docX, y: mouse.docY },
      });
  }
  function onMouseUpDesktop(e) {
    dispatch({ type: END_SELECT });
  }
  function onIconsSelected(iconIds) {
    dispatch({ type: SELECT_ICONS, payload: iconIds });
  }
  function onClickModalButton(text) {
    dispatch({ type: CANCEL_POWER_OFF });
    dispatch({
      type: ADD_APP,
      payload: appSettings.Error,
    });
  }
  function onModalClose() {
    dispatch({ type: CANCEL_POWER_OFF });
  }

  //take care of the intro video displaying
  const [contentDisplay, setContentDisplay] = useState('none');
  function makeBlockApp() {
    setTimeout(() => {
      setContentDisplay('block');
    }, INTROTIME);
  }

  useEffect(() => {
    makeBlockApp();
  }, []);

/* 
  let gifitemsDefault = {
    lab: {
      gif: labgif,
      name: 'Minesweeper',
      startapp: 'PlayerGallery',
      selectedgif: labselect,
      top: 31,
      left: 36.7,
      width: 28.3,
      height: 31.3,
      area: 0.7,
      mouseover: false
    },
    trailer: {
      gif: trailer,
      name: 'Minesweeper',
      startapp: 'PlayerGallery',
      selectedgif: trailer,
      top: 37.2,
      left: 44.1,
      width: 9.4,
      height: 9.4,
      area: 0.7,
      clickable: false
    },
    metamaskbase: {
      gif: metamaskselect,
      name: 'Metamask',
      startapp: 'Metamask',
      selectedgif: metamaskselect,
      top: 62,
      left: 40.7,
      width: 18.3,
      height: 22.3,
      area: 0.7,
      mouseover: false
    },
    metamaskicon: {
      gif: metamaskicon,
      name: 'Metamask',
      startapp: 'Metamask',
      selectedgif: metamaskicon,
      top: 62,
      left: 40.7,
      width: 18.3,
      height: 22.3,
      area: 0.7,
      mouseover: false
    },
    radio: {
      gif: radio,
      name: 'radio',
      startapp: 'Winamp',
      selectedgif: radioselect,
      top: 62,
      left: 60.7,
      width: 18.3,
      height: 22.3,
      area: 0.7,
      mouseover: false
    },
    portal: {
      gif: portal,
      name: 'portal',
      startapp: 'Notepad',
      selectedgif: portalselect,
      top: 52,
      left: 70.7,
      width: 18.3,
      height: 22.3,
      area: 0.7,
      mouseover: false
    },
    cinema: {
      gif: cinema,
      name: 'cinema',
      startapp: 'cinema',
      selectedgif: cinemaselect,
      top: 12,
      left: 20.7,
      width: 18.3,
      height: 22.3,
      area: 0.7,
      mouseover: false
    },
    infoboard: {
      gif: infoboard,
      name: 'infoboard',
      startapp: 'Help',
      selectedgif: infoboardselect,
      top: 22,
      left: 10.7,
      width: 18.3,
      height: 22.3,
      area: 0.7,
      mouseover: false
    },
    arcade: {
      gif: arcade,
      name: 'arcade',
      startapp: 'EasterEgg',
      selectedgif: arcadeselect,
      top: 62,
      left: 5,
      width: 18.3,
      height: 22.3,
      area: 0.7,
      mouseover: false
    }
  };
 */

  let gifitemsDefault = {
    clickus: {
      gif: clickus,
      name: 'trailer icon',
      startapp: 'trailer icon',
      selectedgif: clickus,
    top: 56.2,
    left: 30,
    width: 9.8,
    height: 7.5,
      clickable: false
    },
    mint: {
      gif: mint,
      name: 'trailer icon',
      startapp: 'trailer icon',
      selectedgif: mint,
      top: 55.2,
      left: 52,
      width: 5,
      height: 7.9,
      clickable: false
    },

    trailer: {
      gif: trailer,
      name: 'trailer icon',
      startapp: 'trailer icon',
      selectedgif: trailer,
      
      top: 13.2,
    left: 34.7,
    width: 28.4,
    height: 26.7,
      clickable: false
    },
    trailer2: {
      gif: trailerB,
      name: 'trailer icon',
      startapp: 'trailer icon',
      selectedgif: trailerB,
      top: 24.6,
    left: 7.5,
    width: 18.6,
    height: 14.8,
      clickable: false
    },
/* 


    position: absolute;
    top: 10.4%;
    left: 34.4%;
    width: 28.4%;
    height: 29.4%;
    border: 1px red solid; */

    lab: {
      gif: labgif,
      name: 'Minesweeper',
      startapp: 'PlayerGallery',
      selectedgif: printeroutline,
      top: 44.695,
    left: 46.945,
    width: 25.81,
    height: 45.91,
      area: 1,
      mouseover: false,
      clickable: true
    },
/*     trailer: {
      gif: trailer,
      name: 'Minesweeper',
      startapp: 'PlayerGallery',
      selectedgif: arcadeoutline,
      top: 37.2,
      left: 44.1,
      width: 9.4,
      height: 9.4,
      area: 0.7,
      clickable: false
    }, */
/*     staticmetamaskicon: {
      gif: metamaskicon,
      name: 'metamask icon',
      startapp: 'metamask icon',
      selectedgif: metamaskicon,
      top: 64.4,
      left: 30.1,
      width: 5.4,
      height: 8.4,
      clickable: false
    }, */
    staticmetamaskicon: {
      gif: metamaskicon,
      name: 'metamask icon',
      startapp: 'metamask icon',
      selectedgif: metamaskicon, 
      top: 38.4,
      left: 28.5,
      width: 19.4,
      height: 53.4,
      clickable: false
    },

    /* metamaskbase: {
      gif: metamaskselect,
      name: 'Metamask',
      startapp: 'Metamask',
      selectedgif: test,
      top: 62,
      left: 40.7,
      width: 18.3,
      height: 22.3,
      area: 0.7,
      mouseover: false
    }, */
    metamaskicon: {
      gif: metamaskicon,
      name: 'Metamask',
      startapp: 'Metamask',
      selectedgif: deskoutline,
      top: 63.345,
      left: 29.445,
      width: 16.81,
      height: 25.61,
      area: 1,
      mouseover: false,
      clickable: true
    },
/*     radio: {
      gif: radio,
      name: 'radio',
      startapp: 'Winamp',
      selectedgif: test,
      top: 41.345,
      left: 27.445,
      width: 7.81,
      height: 7.61,
      area: 1,
      mouseover: false
    }, */
    portal: {
      gif: portal,
      name: 'portal',
      startapp: 'Portal',
      selectedgif: portaloutline,
      top: 27.345,
      left: 75.445,
      width: 20.81,
      height: 61.61,
      area: 1,
      mouseover: false,
      clickable: true
    },
    cinema: {
      gif: cinema,
      name: 'cinema',
      startapp: 'PlayerGallery',
      selectedgif: tvoutline,
      top: 12.345,
      left: 34.445,
      width: 29.81,
      height: 28.61,
      area:1,
      mouseover: false,
      clickable: true
    },
    infoboard: {
      gif: infoboard,
      name: 'infoboard',
      startapp: 'Help',
      selectedgif: infooutline,    
      top: 54.345,
      left: 16.745,
      width: 11.81,
      height: 35.61,
      area: 1,
      mouseover: false,
      clickable: true
    },
/*     arcade: {
      gif: arcade,
      name: 'arcade',
      startapp: 'DirectPlayerGallery',
      selectedgif: test,
      top: 55.345,
      left: 5.745,
      width: 9.81,
      height: 33.61,
      area: 1,
      mouseover: false
    }, */
 /*    arcade: {
      gif: arcade,
      name: 'arcade',
      startapp: 'DirectPlayerGallery',
      selectedgif: test,
      top: 55.345,
      left: 5.745,
      width: 9.81,
      height: 33.61,
      area: 1,
      mouseover: false,
      clickable: true
    },
 */

    radio: {
      gif: radio,
      name: 'radio',
      startapp: 'Winamp',
      selectedgif: radiooutline,
      top: 41.345,
      left: 27.445,
      width: 7.81,
      height: 7.61,
      area: 1,
      mouseover: false,
      clickable: true
    },
    smalltv: {
      name: 'smalltv',
      startapp: 'Movie',
      selectedgif: smalltvoutline,
      top: 24.345,
      left: 7.445,
      width: 18.81,
      height: 15.61,
      area: 1,
      mouseover: false,
      clickable: true
    },
  };



  //set div area

  for (const [key, item] of Object.entries(gifitemsDefault)) {

    if (item.clickable != false) {

      item.dwidth = item.width * item.area;
      item.dheight = item.height * item.area;

      item.dleft = item.left + 0.5 * (item.width - item.dwidth);
      item.dtop = item.top + 0.5 * (item.height - item.dheight);

    }

  }


  const [gifitems, SetGifItems] = useState(gifitemsDefault);


  function SetMouseOverItem(key, val) {
    let items = gifitems;

    if(items[key].mouseover!=val){
      
      items[key].mouseover = val;
      SetGifItems(items);
    }

  }


  return (
    <Fragment>
      {INTROTIME > 0 && <StartVideo />}
      {INTROTIME > 0 && <GifImg />}
      <Container
        id='thetestcontainer'
        ref={ref}
        onMouseUp={onMouseUpDesktop}
        onMouseDown={onMouseDownDesktop}
        state={state.powerState}
        style={{ display: contentDisplay }}
      >

        {/* ----------------------------------------------------*/}


        <div className="theApp">
          <div className="theimage">
            {/*    <img style={{ height: '100vh' }} src={bgimg} alt="bg" /> */}
            <img style={{ height: '100vh' }} src={bgimgd} alt="bg" />

             {gifitems &&
              Object.keys(gifitems).map((key, index) => {



                let item = gifitems[key];
                
                if (item.clickable) return (<Fragment key={index}></Fragment>);

                return (
                  <Fragment key={index}>

                    <img style={{
                      position: "absolute",
                      top: item.top + '%',
                      left: item.left + '%',
                      width: item.width + '%'
                      , height: item.height + '%'
                    }} src={ item.selectedgif} alt={item.name}
                    />
{/* 
                   <div style={{
                      border: '1px red solid',
                      cursor: 'pointer',
                      position: "absolute",
                      top: item.top + '%',
                      left: item.left + '%',
                      width: item.width + '%'
                      , height: item.height + '%'
                    }} >
                    </div> */}
                  </Fragment>

                );
              })} 
{/*             {gifitems &&
              <Fragment>

                <img style={{
                  position: "absolute",
                  top: '0%',
                  left: '0%',
                  width: '100%'
                  , height: '100%'
                }} src={test}
                />

              </Fragment>
            } */}



          {gifitems &&
              Object.keys(gifitems).map((key, index) => {

                let item = gifitems[key];

                if(!item.mouseover){
                   return (<Fragment key={index}></Fragment>);
                }

                return (
                  <Fragment key={index}>

              <img style={{
                  position: "absolute",
                  top: '0%',
                  left: '0%',
                  width: '100%'
                  , height: '100%'
                }} src={item.selectedgif}
                />

                  </Fragment>

                );
              })}

            {gifitems &&
              Object.keys(gifitems).map((key, index) => {

                let item = gifitems[key];

                if (item.clickable == false) return (<Fragment key={index}></Fragment>);

                return (
                  <Fragment key={index}>

                    {/*       <img style={{
                    position: "absolute", 
                    top: item.top + '%', 
                    left:item.left + '%', 
                    width: item.width+'%'
                    , height: item.height+'%'
                  }} src={item.mouseover?item.selectedgif:item.gif} alt={item.name}
                    onClick={() => StartApp(item.startapp)} 
  
                    
                    /> */}

                    <div style={{
                      border: '1px red none',
                      cursor: 'pointer',
                      position: "absolute",
                      top: item.dtop + '%',
                      left: item.dleft + '%',
                      width: item.dwidth + '%'
                      , height: item.dheight + '%'
                    }}
                      onClick={() => StartApp(item.startapp)}
                      onMouseDown={()=>PlaySound()}
                      onMouseMove={() => { SetMouseOverItem(key, true) }}
                   /*    onMouseEnter={() => { SetMouseOverItem(key, true) }} */
                      onMouseLeave={() => { SetMouseOverItem(key, false) }}
                    >
                    </div>

                  </Fragment>

                );
              })}






            {/* 
            <img style={{
              cursor: 'pointer',
              position: "absolute", top: positionY + '%', left: positionX + '%', width: '31%', height: '31%'
            }} src={labgif} alt="dance"
              onClick={() => StartApp("Minesweeper")} />

 */}

          </div>
        </div>







        {/* ----------------------------------------------------*/}

        {/* 
        <button onClick={() => StartApp("Minesweeper")}>
          minesweeper
        </button> */}
        {/* 
        <Icons
          icons={state.icons}
          onMouseDown={onMouseDownIcon}
          onDoubleClick={onDoubleClickIcon}
          displayFocus={state.focusing === FOCUSING.ICON}
          appSettings={appSettings}
          mouse={mouse}
          selecting={state.selecting}
          setSelectedIcons={onIconsSelected}
        /> */}
        <DashedBox startPos={state.selecting} mouse={mouse} />
        <Windows
          apps={state.apps}
          onMouseDown={onFocusApp}
          onClose={onCloseApp}
          onMinimize={onMinimizeWindow}
          onMaximize={onMaximizeWindow}
          focusedAppId={focusedAppId} onClickMenuItem={onClickMenuItem}
        />
        <Footer
          apps={state.apps}
          onMouseDownApp={onMouseDownFooterApp}
          focusedAppId={focusedAppId}
          onMouseDown={onMouseDownFooter}
          onClickMenuItem={onClickMenuItem}
        />
        {state.powerState !== POWER_STATE.START && (
          <Modal
            onClose={onModalClose}
            onClickButton={onClickModalButton}
            mode={state.powerState}
          />
        )}
      </Container>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          theme="dark"
        />
    </Fragment>
  );
}

const powerOffAnimation = keyframes`
  0% {
    filter: brightness(1) grayscale(0);
  }
  30% {
    filter: brightness(1) grayscale(0);
  }
  100% {
    filter: brightness(0.6) grayscale(1);
  }
`;
const animation = {
  [POWER_STATE.START]: '',
  [POWER_STATE.TURN_OFF]: powerOffAnimation,
  [POWER_STATE.LOG_OFF]: powerOffAnimation,
};

const Container = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Noto+Sans');
  font-family: 'Aldrich', sans-serif;
  font-size: 11px;
  height: 100%;
  overflow: hidden;
  position: relative;
  background:gray;
  background-size: cover;
  animation: ${({ state }) => animation[state]} 5s forwards;
  *:not(input):not(textarea) {
    user-select: none;
  }
`;


export default WinXP;
