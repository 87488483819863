
import axios from 'axios';


//LIVESWAP CONTRACT (SWAPPING CONTRACTS)--------------------------------------------------------------------------------------------------------

export const MAX_TOKEN = 10000;
export const MVERSE_TOKEN = 0;
export const PURCHASE_MAX = 200;
export var MINT_PRICE_DEFAULT = 0.08;//rinkeby original


export var MVM_CONTRACT_ADDR = '0xD5A73977e6eFfC252A7f1AF4548BA11fF7187616';
export var PLAYER_CONTRACT_ADDR = '0x5c2877fb8992d22b8d764e7d799b7f89d8130ecb';




export var CONTRACT_ADDR = PLAYER_CONTRACT_ADDR;
/* 
CONTRACT_ADDR = PLAYER_CONTRACT_ADDR; */


//-------liveswap------------------------------WL Address===============================================


export var NFTWRAP_CONTRACT_ADDR = '0xaBd9F85030Df1Ec2A3117Fa12b42c73a0d6fdC5d';
export var WL_Signature_Address = 'https://nodexenomintlist.onrender.com/wl/';

//not using this
export var WL_CONTRACT_ADDR = '0xaEC8Dc9d4a65B5aBEBb8eCA638b4Ad3B41Ab9331';//not used anymore

//-------------------------------------ALPHA DOWNLOAD
export var PC_VERSION = 'https://nyc3.digitaloceanspaces.com/animationfiles/Alpha/MVM_XenoOS%20%28Alpha%20Experience%29%20Windows.zip';
export var MAC_VERSION = 'https://animationfiles.nyc3.digitaloceanspaces.com/Alpha/MVM_XenoOS%20%28Alpha%20Experience%29.app.zip';
export var PC_ALT_VERSION = 'https://nyc3.digitaloceanspaces.com/animationfiles/Alpha/MVM_XenoOS%20%28Alpha%20Experience%29%20Windows%20Final%20Round.zip';
export var MAC_ALT_VERSION = 'https://nyc3.digitaloceanspaces.com/animationfiles/Alpha/MVM_XenoOS%20%28Alpha%20Experience%29%20Final%20Round.app.zip';



//-----------------------------------------------------------nft wrapper and xynergy

export var MYSTERY_NFT_CONTRACT_ADDR = '0x92eC3Bc583145bDf299Db38eeFCC08f9432D8064';
export var NFTWRAPPERTWO_CONTRACT_ADDR = '0x258f273F462A919ba3Bc147D25ce4a45a8D82Ef3';


//LIVESWAP--------------------------------------------------------------------------------------------------------

export var rinkebymode = false;//IF MAIN NET THEN FALSE



//=============================================================================================================================================


//live swap area (Kinda ready)------------------------------------------------------------------------------------------------------

export let YOUTUBEMODE = true;

//youtube & trailers----------------------------------
//https://youtu.be/jqlqqnOX3Pk
//https://youtu.be/KeoUsgLoamM
export let youtubeurlA = "https://www.youtube.com/embed/KeoUsgLoamM?autoplay=1&rel=0&showinfo=0&modestbranding=1";//left
//export let youtubeurlB = "https://www.youtube.com/embed/wokZB8k-o50?autoplay=1&mute=1&rel=0&showinfo=0&modestbranding=1";//middle
export let youtubeurlB = "https://www.youtube.com/embed/eaZmJBfF_D4?autoplay=1&mute=1&rel=0&showinfo=0&modestbranding=1";//middle
export let youtubeurlC = "https://www.youtube.com/embed/HnavYWGIo-M?autoplay=1&rel=0&showinfo=0&modestbranding=1";//right

//https://youtu.be/heJVB7bSE4w

//live swap area (SWAPPING TWEETS)----------------------------------------------------------------------------------------------------------------

//tweet urls

/*
only change screen_name probably
*/
export const GetTweetStr = (tokentouse) => {
  let str = "https://twitter.com/intent/tweet?hashtags=XenoInfinityNFT&screen_name=XenoInfinityAI&text=Summon X-XENO for Xeno [" + tokentouse + "] Metaverse Environment v0.1.X at www.XenoInfinity.com";
  return str;
}


// BELOW NOT SWAPPING ATM=================================================================================================================================


//liveswap (not swapping at the moment)--------------------------------------------------------------------------------------------------------



//****NODE SERVER A**** (token data/lucky/ interactive host files [nftinteract html])---------------------
//not swapping (using these urls)

export const api_interacthost = true;//true: other server for interactive / false: this server for interactive

export var INTERACTIVE_HOST_FILES = "https://api.xenoinfinity.com/";
export var BASE_URI = 'https://api.xenoinfinity.com/token/';
export var LUCKYURL = 'https://api.xenoinfinity.com/verse/feelinglucky';



//tweet url (not swapping atm)----------------------------------

//simple xeno url (screen)
export var simplexenourl = "https://animationfiles.nyc3.digitaloceanspaces.com/x/box.png";

//****NODE SERVER B**** (TWEETS)
export const tweeturl = "https://xtwittertweets.onrender.com/tweets/latest";

//trailer host
export var trailerhost = "https://animationfiles.nyc3.digitaloceanspaces.com/x/";

//tweets & rules----------------------------------

export var XenoRuleURL = "https://medium.com/@xenoinfinity/xeno-infinity-x-xeno-event-a2624be8927";
export var XenoTiersURL = "https://medium.com/@xenoinfinity/in-verse-buffs-utility-v1-98b90eca40ea";


//animations (not changing this part)-----------------

//****NODE SERVER C**** (ANIMS)

export var MVM_ANIMATION_BASE_URI = 'https://apidata.multiversevm.com/anim/';

export var PLAYER_ANIMATION_BASE_URI = 'https://apidata.xenoinfinity.com/token/animations/';

export var ANIMATION_BASE_URI = PLAYER_ANIMATION_BASE_URI;



//loot liveswap (used later)--------------------------------------------------------------------------------------------------------


export var TEMP_LOOT_ADDR = '0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00';

export var SLOOT_CONTRACT_ADDR = '0x869ad3dfb0f9acb9094ba85228008981be6dbdde';
export var LOOT_CONTRACT_ADDR = '0xFF9C1b15B16263C61d017ee9F65C50e4AE0113D7';
export var MLOOT_CONTRACT_ADDR = '0x1dfe7Ca09e99d10835Bf73044a23B73Fc20623DF';


//rinkeby liveswap (not used) (SWAPPING-TEST)--------------------------------------------------------------------------------------------------------

export var testowner = false;


//liveswap
if (rinkebymode) {//local or rinkeby


  //basically all the one node server---------------------------------(digital ocean trailers) 


  //trailerhost = "http://localhost:5000/trailers/";

  //basically all the one node server---------------------------------(api.xenoinfinity.com) 
  //base uri for tokens metadata
  //INTERACTIVE_HOST_FILES = "http://localhost:5000/";
  //BASE_URI = 'http://localhost:5000/token/';
  //feeling lucky server (still the base uri for token)
  //LUCKYURL = 'http://localhost:5000/verse/feelinglucky';


  //RINKEBY
  //LOOT_CONTRACT_ADDR = "";
  //SLOOT_CONTRACT_ADDR = "";
  //MLOOT_CONTRACT_ADDR = "";


  MVM_CONTRACT_ADDR = '';
  PLAYER_CONTRACT_ADDR = '';

  CONTRACT_ADDR = PLAYER_CONTRACT_ADDR;

  WL_CONTRACT_ADDR = '';
  WL_Signature_Address = 'https://nodexenomintlist.onrender.com/wl/';

  NFTWRAP_CONTRACT_ADDR = ''


  //-----rinkeby

  MVM_CONTRACT_ADDR = '';
  PLAYER_CONTRACT_ADDR = '';

  CONTRACT_ADDR = PLAYER_CONTRACT_ADDR;

  WL_CONTRACT_ADDR = '';
  WL_Signature_Address = 'https://nodexenomintlist.onrender.com/wl/';

  NFTWRAP_CONTRACT_ADDR = ''




  PLAYER_CONTRACT_ADDR = '0x681690a49afaa3B24f51d2f83E9D40e662bdDD61';

  CONTRACT_ADDR = PLAYER_CONTRACT_ADDR;
  MYSTERY_NFT_CONTRACT_ADDR = '0x2c37323fE7D1DC44B0D4B76A5B4CC9e569Ea5c3e';
  NFTWRAPPERTWO_CONTRACT_ADDR = '0x2375Ade76b6A410eA1Fb168Cdb8132a5748a806A';

}


//live swap load screen (not used)--------------------------------------------------------------------------------------------------------



let PreDeployMode = false;
//export const INTROTIME = 11000; pre intro time 9000
// export const INTROTIME =11000;
// export const PREINTROTIME =9000;
//usually 5000

export var PRELAUNCHTIME = 10000000000;
export var PREINTROTIME = 0;//set to 5000 once ready
export var GIFTIME = 2000;//set to 5000 once ready
//export const PREINTROTIME =0;//set to 5000 once ready
//export const PREINTROTIME =0;//set to 5000 once ready


export var INTROTIME = PREINTROTIME + GIFTIME;
export var HIDEPREINTRO = true;

let NOINTRO = false;

if (NOINTRO) {
  INTROTIME = 0;
}

if (PreDeployMode) {
  HIDEPREINTRO = false;
  PREINTROTIME = PRELAUNCHTIME;

}


//live swap lucky steps--------------------------------------------------------------------------------------------------------

export const lucky_server_mode = true;//lucky server

export const luckydefault = [0.1, 0.2, 0.3, 0.4, 0.5]; //during network error

export const luckysteps = 1000;//over 6000 
export const playerthreshold = 6000;
export const defaultplayerluckysetmax = 25;
export const teststep = -1;

export const minimumvalue = 10;

//liveswap--------------------------------------------------------------------------------------------------------


export const MAX_COUNT_ONE = 200;

//not live
export const testnftaddr = "0x12fcf3ad1c86bdf3f19c604cfd99ee02d51517be";

//not live
export const testwalletmode = false;
export const testnftlistmode = false;
export const SORTNFTMODE = true;

export const PLAYERNAMENFT = "Xeno Infinity";
export const PLAYERNAME_NFT = "Xeno Infinity NFT";
export const PLAYERNAMENFTS = "Xeno Infinity NFTs";
export const PLAYERNAME = "Xeno Avatar";
export const PLAYERNAMES = "Xeno Avatars";


let ipfsPrefix = 'ipfs://';
let httpPrefix = 'https://ipfs.io/ipfs/';



/* 
export let BASE_URI ='https://api.multiversevm.com/';
*/

/*
uribasein:"https://localhost:5000/Outputwrite/"
maxusertokens:8000
mversetokens:80
maxlinkcountin:16
mintprice:50000000000000000
initpause:false
*/

//this version is mainly used locally 
export var INTERACTIVE_URI = 'nftinteract/index.html';
export var INTERACTIVE_TOKEN_URI = 'nftinteract/index.html?tokenseed=';
export var INTERACTIVE_TOKEN_URI2 = 'nftinteract/index.html?tokenseed=';

export var INTERACTIVE_TOKEN_URI_EXTRA = 'extranftinteract/index.html?tokenseed=';
export var INTERACTIVE_TOKEN_URI_ALPHA = 'alphainteractd/index.html?tokenseed=';

export var INTERACTIVE_TOKEN_URI_CHALLENGE = 'challengeinteract/index.html?tokenseed=';



if (api_interacthost) {
  let thehost = INTERACTIVE_HOST_FILES;

  INTERACTIVE_URI = thehost + INTERACTIVE_URI;
  INTERACTIVE_TOKEN_URI = thehost + INTERACTIVE_TOKEN_URI;
  INTERACTIVE_TOKEN_URI2 = thehost + INTERACTIVE_TOKEN_URI2;

}

export const LoginMessage = 'multiversevmlogin';

//liveswap
export let computerurl = 'computer.html';


//liveswap
export const tempurl = false;
export const animfromipfs = false;





//live swap area----------------------------------------------------------------------------------------------------------------

export const ONE_TOKEN = 'One Token';
export const TOKEN_GALLERY = 'All ' + PLAYERNAMENFTS;
export const INTERACTIVE = 'Interactive';
export const MINT_TOKEN = 'Mint';
export const LOADING = 'Loading';
export const MY_TOKENS = 'My ' + PLAYERNAMENFTS;

export const USER_TOKENS = 'User Tokens';

export const RANDOM_INTERACTIVE = 'Interactive ' + PLAYERNAME_NFT;
export const RANDOM_INTERACTIVE_VERSE = 'MVM & Xeno Update-X';
export const SELECT_TOKENS = 'Select Tokens';
export const RANDOM_TOKEN = PLAYERNAMENFT + ' Token';

export const INTERACTIVE_VERSE = 'Interactive Verse';


export const INTERACTIVE_ALPHA = 'Interactive Alpha';
export const PLAY_ALPHA = 'Play Alpha';

export const RANDOM_INTERACTIVE_ALPHA = 'Exploration Experience Demo';


export const CORRECT_NETWORK_WINDOW = 'Correct Network Window';
export const EXTERNAL_LINK = 'External Link';

export const HOME_WINDOW = 'HOME_WINDOW';

export const X_XENO_EVENT = 'X-XENO🔗';

export const MYSTERY_LOOT_CHALLENGE = 'Final Mystery Challenge';
export const REVEAL_MYSTERY_LOOT = 'Reveal Mystery Loot';



export var CORS_URL_B = "https://xcorsanywhereb.onrender.com/";
//specific for mverse

export const mverseTokensArray = [];

export const StillMintable = (alltokensin) => {
  //remove the count of mversetokens
  //1,2,8000,8001
  //mintedlength=2

  let mintedlength = alltokensin.length - mverseTokensArray.length;
  return MAX_TOKEN <= mintedlength;
};

export const IncludeMverseTokens = async (alltokens) => {
  alltokens.push(...mverseTokensArray);
  return alltokens;
};

export const TokenToMverseIncluded = (tokeninput) => {
  //if input >=8000 then mverse

  if (tokeninput < MAX_TOKEN) {//under 8000
    return tokeninput;
  } else {
    //looping first
    let prev = MAX_TOKEN;

    for (let i = 0; i < mverseTokensArray.length; i++) {
      //8000 then prev;


      if (mverseTokensArray[i] > tokeninput) {
        return mverseTokensArray[i];//return first larger than this
      }
    }

  }

  return -1;
};


//loot contract
//mloot contract

export const autotokenuri = urlinput => {
  //console.log('is prefx', ipfsPrefix);

  return urlinput;
  /* 
    if (tempurl) {
      console.log('using temp tokenuri');
    } else {
      return urlinput;
    } */
};

export const autourl = urlinput => {
  //console.log('is prefx', ipfsPrefix);

  if (urlinput === undefined) {
    return "";
  }
  let url = urlinput.replace(ipfsPrefix, httpPrefix);
  console.log('autourl ', url);
  return url;
};

export const animationurl = token => {

  /* 
  let index = parseInt(token) % 2;
  let indexstr = index.toString() + '.mp4'; */
  let indexstr = token + '.mp4';
  return autourl(ANIMATION_BASE_URI + indexstr);
};

export const playeranimationurl = token => {

  /* 
  let index = parseInt(token) % 2;
  let indexstr = index.toString() + '.mp4'; */
  let indexstr = token + '.mp4';
  return autourl(PLAYER_ANIMATION_BASE_URI + indexstr);
};

export const mvmanimationurl = token => {

  /* 
  let index = parseInt(token) % 2;
  let indexstr = index.toString() + '.mp4'; */
  let indexstr = token + '.mp4';
  return autourl(MVM_ANIMATION_BASE_URI + indexstr);
};

//export let PREVIEW_ANIM = animationurl("./img/preview.mp4");
export let PREVIEW_ANIM = "./preview.mp4";
export let MYSTERY_ANIM = "./mystery.mp4";

export const getRandomInt = max => {
  return Math.floor(Math.random() * max);
};

export const correctChain = chainid => {
  if (chainid == '0x1' || chainid == '0x539' || chainid == '0x4') {
    return true;
  } else {
    return false;
  }
};

export const chainString = chainid => {
  console.log('chain id ', chainid);
  switch (chainid) {
    case '0x1':
      return 'Ethereum Main Network';
    case '0x3':
      return 'Ropsten Test Network';
    case '0x4':
      return 'Rinkeby Test Networkk';
    case '0x5':
      return 'Goerli Test Network';
    case '0x2a':
      return 'Kovan Test Network';
    case '0x539':
      return 'Local Testnet';
    default:
      return 'Unknown Network';
  }

  return 'Unknown Network';
};

//0 static links
//1 dynamic user links
export const fetchLinks = async (contractin, tokenId, type) => {
  let links = [];
  if (typeof window.ethereum !== 'undefined') {
    console.log('fetching token id uri');

    try {
      if (type == 0) {
        links = await contractin.GetLinks(tokenId);
      } else {
        links = await contractin.GetDynamicLinks(tokenId);
      }
      //GetDynamicLinks
      console.log('token links: ', links);
      //setGlobal({extradata:data
    } catch (err) {
      console.log('token links Error: ', err);
    }
  } else {
    console.log('ethereum api not detected');
  }

  return links.map(item => item.toNumber());
}

export const processLinkarray = (allTokens, linkarray) => {
  let formattedarray1 = [];
  for (let i = 0; i < linkarray.length; i++) {
    let item = linkarray[i];

    let mintedval = allTokens.includes(item);
    formattedarray1.push({
      itemid: item,
      minted: mintedval,
      classstr: mintedval ? 'minted' : 'notminted',
    });
  }

  return formattedarray1;
}



export const SignMessage = async (provider, message) => {

  const signer = provider.getSigner();
  const signature = await signer.signMessage(message);
  /* 
    console.log(`signed message is: ${signature}`);
    alert(signature); */

  return signature;

}

let waiting_for_login_result = false;

export const AutoLoginToContinue = async (providerin, addressin, ethersin) => {

  let returnval = {};

  try {

    let localsignature = window.localStorage.getItem('signature');
    let message = LoginMessage;

    let shouldresign = false;

    if (localsignature == null) {
      shouldresign = true;
    } else {
      //check signature with message

      const localsignedaddress = ethersin.utils.verifyMessage(message, localsignature);

      //check if same with current addresss
      shouldresign = (addressin.toUpperCase() != localsignedaddress.toUpperCase());
    }

    if (shouldresign) {
      /* 
            if(!waiting_for_login_result){
            
              waiting_for_login_result=true;
       */

      let signature = await SignMessage(providerin, message);
      window.localStorage.setItem('signature', signature);
      localsignature = signature;


      /* 
      waiting_for_login_result=false;
    
    } */
    }

    //so now have localsignature
    return {
      address: addressin,
      signature: localsignature
    };

  } catch (ex) {
    return {
      address: '',
      signature: ''
    };
  }

}


//token
//type (full/lucky/trial)
//tokentype
//typeclass (optional for the select tokens list only)
//selected (optional)
export const defaultSelected = {
  token: "not selected",
  type: "#",
  selected: false
};

export const MVM = "mvm";
export const PLAYER = "player";

export const FULL = "Full Access";
export const LUCKY = "Feeling Lucky";
export const TRIAL = "Trial";

export var luckymvmtokens = [];
export var luckyplayertokens = [];
export var luckyratio = [];

function GetStepLuckyTokens(ratios, tokenlength = 100) {

  let luckydatalength = defaultplayerluckysetmax;

  if (teststep != -1) {
    tokenlength = teststep;
  }

  if (tokenlength > playerthreshold) {

    let multiple = Math.floor(tokenlength / luckysteps);

    luckydatalength = multiple * luckysteps;

  }

  let length = luckydatalength;

  console.log('luck step token length', tokenlength);


  var result = [];

  for (let i = 0; i < ratios.length; i++) {
    let intval = Math.floor(length * ratios[i]);
    if (!result.includes(intval)) {

      result.push(intval);
    }
  }

  console.log('lucky tokens 2', result);

  return result;
}

/* 
function GetStepLuckyTokens(ratios,tokenlength=100){
  let length=tokenlength;

  let num=length+1000;

  num=Math.floor(num/1000);

  console.log('num is: ',num);




  var result=[];

  for(let i=0;i<ratios.length;i++){
    let intval=Math.floor(length*ratios[i]);
    if(!result.includes(intval)){

      result.push(intval);
    }
  }

  console.log('lucky tokens 2',result);

  return result;
}
 */


async function GetLuckyInt(length) {

  let returnresult = [];
  if (luckyratio.length == 0) {

    let result = null;

    if (lucky_server_mode) {
      result = await fetchsimpledata(LUCKYURL);

      console.log('lucky result:', result);
    } else {
      result = luckydefault;
    }

    if (result == null) {
      result = luckydefault;
    }

    luckyratio = result;
  }

  for (let i = 0; i < luckyratio.length; i++) {
    let intval = Math.floor(length * luckyratio[i]);

    if (!returnresult.includes(intval)) {
      returnresult.push(intval);
    }

  }

  return returnresult;
}

async function fetchsimpledata(url) {
  console.log('axios', url);



  try {
    let response = await axios
      .get(url, {
        responseType: 'json',
      });

    var data = response.data;
    console.log('response data');
    console.log(data);

    return data;

  } catch (error) {
    console.log(error);
  }
  return null;
}

export const GetLuckyTokens = async (tokentype, tokenlength = 8000) => {

  switch (tokentype) {
    case MVM:

      let mvmtokenlength = 8000;

      if (luckymvmtokens.length > 0) {
        return luckymvmtokens;
      }

      luckymvmtokens = await GetLuckyInt(mvmtokenlength);

      luckymvmtokens.sort(function (a, b) {
        return a - b;
      });

      luckymvmtokens.unshift(951);

      console.log('lucky tokens', luckymvmtokens);

      return luckymvmtokens;
      break;

    case PLAYER:
      //test if lucky tokens are within range too.
      if (luckyplayertokens.length > 0) {
        return luckyplayertokens;
      }

      let initint = await GetLuckyInt(tokenlength);

      //ratio initialized, load ratio

      let result = GetStepLuckyTokens(luckyratio, tokenlength);

      result.sort(function (a, b) {
        return a - b;
      });
      luckyplayertokens = result;

      //add one at the beginning
      //luckyplayertokens.unshift(4);
      luckyplayertokens.unshift(128);


      console.log('lucky player tokens', luckyplayertokens);

      return luckyplayertokens;
      /* 
            return luckyplayertokens;
            return [6903,1000,1001,1002,1003,1004,1005]; */
      break;
  }
}

export const GetTrialTokens = async (tokentype, tokenlength = 8000) => {
  let result = [];

  switch (tokentype) {
    case MVM:


      let mvmtokenlength = 8000;

      for (let i = 0; i < mvmtokenlength; i++) {
        result.push(i);
      }

      return result;

      break;
    case PLAYER:

      for (let i = 0; i < tokenlength; i++) {
        result.push(i);
      }

      return result;

      break;
  }

}

export const StillDefault = (token) => {
  return (token.type == "#");
}

export const IsOwner = async (contractin, address, token) => {
  if (typeof window.ethereum !== 'undefined') {
    console.log('fetching token owner');

    try {
      const data = await contractin.ownerOf(Number(token));
      console.log('token Owner: ', data);
      //setGlobal({extradata:data})

      return address.toUpperCase() == data.toUpperCase();

    } catch (err) {
      console.log('token Owner Error: ', err);
    }

  } else {
    console.log('ethereum api not detected');
  }

  return false;
}

export const GetStartTokenType = async (address, token, tokentype, playercontract, mvmcontract, tokenlength = 8000) => {

  const contract = ((tokentype == MVM) ? mvmcontract : playercontract);

  let resulttokenlength = tokenlength;

  if (tokentype == MVM) {
    resulttokenlength = 8000;
  }

  let isowner = await IsOwner(contract, address, token);

  if (isowner) {
    return FULL;
  } else {
    //see if it is lucky tokens
    let luckylist = await GetLuckyTokens(tokentype, resulttokenlength);

    if (luckylist.includes(Number(token))) {
      let a = 100;
      return LUCKY;
    } else {
      let a = 100;

      return TRIAL;
    }

  }
}

export const GetQuickTokenURI = async (token) => {
  return BASE_URI + token;
}

export const GetPlayType = async (mvmtype, playertype) => {
  //guardian mode can be used in game

  let jumps = -1;
  let time = -1;

  switch (mvmtype) {

    case FULL:

      switch (playertype) {
        case FULL:
          jumps = 20;
          time = -1;
          break;
        case LUCKY:
          jumps = 4;
          time = 0.5;
          break;
        case TRIAL:
          jumps = 1;
          time = 0.4;
          break;
      }

      break;
    case LUCKY:

      switch (playertype) {
        case FULL:
          jumps = 10;
          time = -1;
          break;
        case LUCKY:
          jumps = 2;
          time = 0.5;
          break;
        case TRIAL:
          jumps = 1;
          time = 0.4;
          break;
      }

      break;
    case TRIAL:

      switch (playertype) {
        case FULL:
          jumps = 4;
          time = -1;
          break;
        case LUCKY:
          jumps = 1;
          time = 0.5;
          break;
        case TRIAL:
          jumps = 1;
          time = 0.4;
          break;
      }

      break;

  }

  if (time != -1) {
    time = time * 60;
  }

  return {
    jumps: jumps,
    time: time,
    playertype: playertype,
    mvmtype: mvmtype
  }
}



/* 


export const GetPlayType = async (mvmtype, playertype) => {
  //guardian mode can be used in game

  let jumps = -1;
  let time = -1;

  switch (mvmtype) {

    case FULL:

      switch (playertype) {
        case FULL:
          jumps = 20;
          time = -1;
          break;
        case LUCKY:
          jumps = 4;
          time = 1.5;
          break;
        case TRIAL:
          jumps = 1;
          time = 1.25;
          break;
      }

      break;
    case LUCKY:

      switch (playertype) {
        case FULL:
          jumps = 10;
          time = -1;
          break;
        case LUCKY:
          jumps = 2;
          time = 1.5;
          break;
        case TRIAL:
          jumps = 1;
          time = 1;
          break;
      }

      break;
    case TRIAL:

      switch (playertype) {
        case FULL:
          jumps = 4;
          time = -1;
          break;
        case LUCKY:
          jumps = 1;
          time = 1.5;
          break;
        case TRIAL:
          jumps = 1;
          time = 0.5;
          break;
      }

      break;

  }

  if (time != -1) {
    time = time * 60;
  }

  return {
    jumps: jumps,
    time: time,
    playertype: playertype,
    mvmtype: mvmtype
  }
}



export const TokenType=async (token,contract,address) =>{
  switch(tokentype){
    case MVM:
      return [100,200,201,202,203,204,205];
    break;
    case PLAYER:
      return [1000,2000,2001,2002,2003,2004,2005];
    break;
  }
}https://api.multiversevm.com/1

 */