//default value in original?

//seems that it is important to just copy the values 
//when using the reducer? so a new object to re-render directly?

import Web3Modal from "web3modal";
import { ethers } from 'ethers'
import MultiverseVirtualMachine from 'artifacts/contracts/MultiverseVirtualMachine.sol/MultiverseVirtualMachine.json'



const providerOptions = {
  /* See Provider Options Section */
};


var defaultDappState = {
  address:window.ethereum?window.ethereum.selectedAddress:null,
  provider:null,
  contract:null,
  extradata:"",
  web3Modal:new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions // required
  })
};

//set provider if exists
if(window.ethereum!=null){
/*   const provider = new ethers.providers.Web3Provider(window.ethereum)
  const contract = new ethers.Contract(contractAddress, MultiverseVirtualMachine.abi, provider)
  defaultDappState.provider=provider;
  defaultDappState.contract=contract; */
}

















export default (originalstate = defaultDappState, action) => {
  switch (action.type) {
    case 'DAPP_STATE_UPDATE':
        console.log('test reducer');

        action.payload.extradata+=" reducer data";
        return  { ... action.payload };
    case 'SET_PROVIDER_CONTRACT':

      let data=action.payload;
      var reducedResult={ ...originalstate,provider:data.provider,contract:data.contract };

      return reducedResult;
    case 'SET_PROVIDER':

      let data2=action.payload;
      var reducedResult={ ...originalstate,provider:data2};

      return reducedResult;
    default:
        return originalstate;
  }
};

/** export default (original = {
    Wallet: {
      address:"wallet address",
      connected:false
    },
    Dapp: {
      
    },
    extradata:""
  }, action) => {
  switch (action.type) {
    case 'DAPP_STATE_UPDATE':
 
        console.log('test reducer');
        //console.log(original);
        //console.log(action.payload);
        return action.payload;
    default:
        return original;
  }
};*/