import React from 'react';

import { Button } from 'react95';

const LootItemDiv = ({ item, onClickFn }) => {
  return (
    <div className="lootItem">
      <Button className="btn" onClick={() => onClickFn(item)}>
        {item.img && (
          <img
            alt="character"
            src={item.img}
            style={{ borderRadius: '5px', width: '50px' }}
          ></img>
        )}
        <div>{item.name}</div>
      </Button>
    </div>
  );
};

export default LootItemDiv;
