import styled from 'styled-components';
import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { toast } from "react-toastify"
import axios from 'axios';
import TokenImage from './TokenImage';
import XenoInfinity from 'artifacts/contracts/XenoInfinity.sol/XenoInfinity.json'
import WLXenoDirect from 'artifacts/contracts/WLXenoDirect.sol/WLXenoDirect.json'

import { errors, ethers } from 'ethers';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';
import modernDark from 'react95/dist/themes/modernDark';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

import {

  PREVIEW_ANIM, PC_VERSION, MAC_VERSION, MYSTERY_ANIM
} from 'lib/lib.js';

import {
  Button,
  Panel,
} from 'react95';


const ClaimMysteryLoot = ({ JumpToInteractiveVerse, winGame }) => {
  //load the token data here

  const [dappState, setDappState] = useGlobal();
  const provider = dappState.provider;
  const contract = dappState.contract;
  const address = dappState.address;
  const mysterynftContract = dappState.mynftcontract;
  const nftwtwoContract = dappState.nftwtwocontract;

  const [preview, setPreview] = useState(MYSTERY_ANIM);
  const [accountInfo, setAccountInfo] = useState([])
  const [ownedLoot, setOwnedLoot] = useState(0)
  const [canCliam, setCanClaim] = useState(false)
  const [lootMintStatus, setLootMintStatus] = useState(false)
  const [xenoClaimableAmount, setXenoClaimableAmount] = useState(0)
  const [textone, SetTextOne] = useState("")
  const [texttwo, SetTextTwo] = useState("")

  useEffect(() => {
    getWhiteList()
    checStatus()
  }, [address])

  const getWhiteList = () => {
    axios.get('https://mvmnodepub.onrender.com/test/getWhiteListAddress/' + address)
    // axios.get('http://localhost:5000/test/getWhiteListAddress/' + address)

    .then(response => {
      const data = response.data
      if (data) {
        setAccountInfo(data)
        setCanClaim(true)
      } else {
        setCanClaim(false)
      }
    })
  }
  const checStatus = async () => {
    setLootMintStatus(await mysterynftContract.minted(address))

    const whitelistAmount = await nftwtwoContract.whitelistAmount(address)
    const mintedAmount = await nftwtwoContract.amountMinted(address)
    setXenoClaimableAmount(whitelistAmount - mintedAmount)
    setOwnedLoot(Number(await mysterynftContract.balanceOf(address, 0)))
  }

  const handleClaimLoot = async () => {
    console.log(accountInfo)
    let tx = {};
    try {
      tx = await mysterynftContract.signature_mint(accountInfo.sign, accountInfo.amount);
      tx.wait(
        console.log('waiting for transaction to go complete')
      ).then(receipt => {
        console.log(receipt)
        toast.success(`Transaction Complete!`)
        SetTextOne("Xynergy Box Transaction Success!")
        setLootMintStatus(true)
      }).catch(err => {
        toast.error(parseError(err))
      });
    } catch (error) {
      toast.error(parseError(error))
    }
  }

  const handleClaimXeno = async () => {
    let tx = {};
    try {
      tx = await nftwtwoContract.mint(xenoClaimableAmount);
      tx.wait(
        console.log('waiting for transaction to go complete')
      ).then(receipt => {
        console.log(receipt)
        toast.success(`Transaction Complete!`)
        SetTextTwo("Xeno Transaction Success!")

        setXenoClaimableAmount(0)
      }).catch(err => {
        toast.error(parseError(err))
      });
    } catch (error) {
      toast.error(parseError(error))
    }
  }

  const parseError = (err) => {
    if (typeof err == 'object') {
      if (err.data)
        return err.data.message.replace('Error: VM Exception while processing transaction: reverted with reason string ', '')
      else
        return err.message
    }
    return err
  }

  return (
    <Panel
      variant="outside"
      shadow
      style={{
        padding: '0.5rem',
        lineHeight: '1.5',
        display: 'block',
        justifyContent: 'center',
        minWidth: '60%',
        maxWidth: '500px',
      }}
    >

      <PlayDiv style={{ display: 'flex', width: '100%' }}>

        <Panel
          variant="well"
          style={{
            minHeight: 200,
            width: 210
          }}
        >
          <TokenImage url={preview}></TokenImage>
        </Panel>
        {winGame ?
          <Panel
            variant="well"
            style={{
              padding: '10px',
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'space-around',
              alignContent: 'center',
              flexDirection: 'column',
              width: 'calc(100% - 210px)'
            }}
          >
            <div className='titleitem'>You have won the Challenge!</div>

            <div className='claimbtn_part'>
              {!lootMintStatus ?
                <Button
                  className='claimbtn'
                  onClick={handleClaimLoot}
                  style={{ margin: '5px', padding: '5px' }}
                >
                  Get your Xynergy Box(es)!
                </Button>
                :
                ""
              }




              {xenoClaimableAmount > 0 ?
                <Button
                  className='claimbtn'
                  onClick={handleClaimXeno}
                  style={{ margin: '5px', padding: '5px' }}
                >
                  Get your Xeno(s)!
                </Button>
                :
                ""
              }

              {textone != "" ?
                <div className='titleitem'>{textone}</div>
                :
                ""
              }

              {texttwo != "" ?
                <div className='titleitem'>{texttwo}</div>
                :
                ""
              }

            </div>
          </Panel>
          :
          <Panel
            variant="well"
            style={{
              padding: '10px',
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'space-around',
              alignContent: 'center',
              flexDirection: 'column',
              width: 'calc(100% - 210px)'
            }}
          >
            <div className='titleitem'>Your Currently Own</div>
            <div className='titleitem'>{ownedLoot} Xynergy Boxes</div>
              <Button
                className='claimbtn'
                onClick={() => { getWhiteList();  checStatus()}}
                style={{ margin: '5px', padding: '5px' }}
              >
                Refresh
              </Button>
            <div className='claimbtn_part'>
              {canCliam ?
                <Button
                  className='claimbtn'
                  onClick={() => JumpToInteractiveVerse()}
                  style={{ margin: '5px', padding: '5px' }}
                >
                  Complete the minigame!
                </Button>
                :
                <div>
                  </div>
               /*  <Button
                  className='claimbtn'
                  disabled="disabled"
                  style={{ margin: '5px', padding: '5px' }}
                >
                  Loading...
                </Button> */
              }

              {/* <span>you can claim 0 Mystery Loot</span> */}
            </div>
          </Panel>
        }
      </PlayDiv>

      <Panel
        variant="well"
        style={{
          textAlign: 'center',
          padding: '20px',
          width: '100%'
        }}
      >
        <LocalVerDiv className='pcverdiv'>
          <Panel variant="well" className='pcverwell'>
            <span className='pcversapn'>
              Xynergy Boxes that radiate mysterious energy
            </span>
            <div className='out_link_part'>
              <a className='atierlink' href="https://etherscan.com/address/0x92eC3Bc583145bDf299Db38eeFCC08f9432D8064" target="_blank">Etherscan</a>
              <a className='atierlink' href="https://opensea.io/assets/0x92ec3bc583145bdf299db38eefcc08f9432d8064/0" target="_blank">Opensea</a>
            </div>
          </Panel>
        </LocalVerDiv>
      </Panel>
    </Panel>
  );
};
const LocalVerDiv = styled.div`
  .pcverwell{
    padding: 40px 10px;
    font-size: 13px;
    color:white;
    width:100%;
  }

  .pcverdiv{
    font-size: 11px!important;
    margin-bottom:10px;
  }
  .pcversapn{
    margin: 20px 0px;
    width:100%;
  }

  .atierlink{
    color: #25d711;
    text-decoration: underline;

  }
  .specialtext{
    color: #FFD700;

  }
  .out_link_part{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 40px 0 10px 0;
  }

`;

const StyledDiv = styled.div`
.thevid{
      width: 100%;
  height: 100%;
  max-height: 100%;
}

.youtubevid{
  width: 100%;
  height: 268px;
}

.vidDiv{    width: 100%;
  
  background: black;
}

.bold{
  font-weight:bold;
  color:white;
}

.description{
  font-size: 14px;
}

.atierlink{
  color: #25d711;
  text-decoration: underline;

}

`;

const PlayDiv = styled.div`
.atierlink{
  color: #25d711;
  text-decoration: underline;

}
.hinttext{
  color: lightgray;
  font-size: 12px;
}
.adjtext{
  height: 50px;
  color: rgb(231, 231, 231);
  font-size: 16px!important;
  margin:0px;
}

.claimbtn_part{
  display: flex;
  flex-direction: column;
  & span {
    color: rgb(231, 231, 231);
    font-size: 11px;
  }
}

.titleitem{
  color: rgb(231, 231, 231);
  font-size: 18px;
}
`;

export default ClaimMysteryLoot;
