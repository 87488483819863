
import {autourl,BASE_URI
  ,ONE_TOKEN,TOKEN_GALLERY,INTERACTIVE
  ,MINT_TOKEN,MY_TOKENS,USER_TOKENS
  ,RANDOM_INTERACTIVE,RANDOM_TOKEN
  } from 'lib/lib.js'


  const More = [
    {
      type: 'item',
      disable: true,
      text: 'Metaverse Connector'
    }
  ];


export default { More};
