import React, { Fragment, useState, useEffect } from 'react';

import {PREINTROTIME,INTROTIME
  
} from 'lib/lib.js'

function GifImg(props) {
  const [imgDisplay, setImageDisplay] = useState('none');
  function changeImageDisplay() {
    setTimeout(() => {
      setImageDisplay('flex');
    }, PREINTROTIME);
     setTimeout(() => {
      setImageDisplay('none');
    }, INTROTIME); 
  }
  useEffect(() => {
    changeImageDisplay();
  }, []);
  return (
    <div style={{ display: imgDisplay,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'space-around'
    
    }} className="videoFull">
      <div className="centerSom bg-black  testImg">
        <img src="./img/intrologo.gif" alt="gif " className="coverImg" />
        {/* <img src="./img/loading-original-size.gif" alt="gif " className="coverImg" /> */}
      </div>
    </div>
  );
}

export default GifImg;



/*
import React, { Fragment, useState, useEffect } from 'react';

function GifImg(props) {
  const [imgDisplay, setImageDisplay] = useState('none');
  function changeImageDisplay() {
    setTimeout(() => {
      setImageDisplay('block');
    }, 9000);
    setTimeout(() => {
      setImageDisplay('none');
    }, 11000);
  }
  useEffect(() => {
    changeImageDisplay();
  }, []);
  return (
    <div style={{ display: imgDisplay }} className="videoFull">
      <div className="centerSom bg-black  testImg">
        <img src="./img/giphy.gif" alt="gif " className="coverImg" />
      </div>
    </div>
  );
}

export default GifImg;
 */