import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import styled from 'styled-components';
// add child div to capture mouse event when not focused

function TwitterBot({ onClose, isFocus }) {

  return (


    <Div>
      <div className='caption'>
        MultiverseVM Listens To All Tweets<br/>'#MultiverseVM' or '@MultiverseVM' 
      </div>

      <iframe
        src="computer/twitterboard.html"
        frameBorder="0"
        title="Twitter (Earth sim)"
        style={{
          display: 'block',
          width: '100%',
          height:' calc(100% - 50px)'
        }}
      />
    </Div>
  );
}
const Div = styled.div`
width: 100%;
height: 100%;
display: block;
background:url('img/bg.jpg');
.caption{
  color: white;    height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  padding-left: 5px;
    padding-right: 5px;
}

`;

export default TwitterBot;
