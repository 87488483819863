//import React, { useState } from 'react';
import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';

import styled from 'styled-components';

import { WindowDropDowns } from 'components';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';
import modernDark from 'react95/dist/themes/modernDark';



import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';
import bg from 'assets/windowsIcons/Multiversebg.jpg';

import {
  autourl,
  getRandomInt,
  BASE_URI,
  ONE_TOKEN,
  TOKEN_GALLERY,
  INTERACTIVE,
  MINT_TOKEN,
  MY_TOKENS,
  USER_TOKENS,
  RANDOM_INTERACTIVE,
  RANDOM_TOKEN,CORRECT_NETWORK_WINDOW,correctChain,EXTERNAL_LINK ,IncludeMverseTokens
} from 'lib/lib.js';

import {
  styleReset,
  Button,
  TextField,
  WindowHeader,
  WindowContent,
  List,
  ListItem,
  Divider,
  Cutout,
  Toolbar,
  Panel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeadCell,
  TableDataCell,
  Window,
} from 'react95';

import dropDownData from './dropDownData';

import axios from 'axios';

import OneToken from './OneToken';
import TokenGallery from './TokenGallery';
import Interactive from './Interactive';
import ExtraInteractive from './ExtraInteractive';
import MintToken from './MintToken';
import UserTokens from './UserTokens';
import CorrectNetworkWindow from './CorrectNetworkWindow';
import ExternalLink from './ExternalLink';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
  ${styleReset}
`;
export default function Gallery({ onClose,onClickMenuItem }) {
  const [dappState, setDappState] = useGlobal(); //entire dappstate

  const contract = dappState.contract;
  const address = dappState.address;
  const readexternal=dappState.externalstatread;

  console.log('dapp state', dappState);

  const [wordWrap, setWordWrap] = useState(false);


//const [view, setView] = useState(MINT_TOKEN);
//  const [view, setView] = useState(TOKEN_GALLERY);
  
  //const [view, setView] = useState(ONE_TOKEN);
   const [view, setView] = useState(INTERACTIVE);

  const [token, setToken] = useState('0');
  const [interactiveURL, setInteractiveURL] = useState('');

  const [baseURI, setBaseURI] = useState(BASE_URI);

  const [interactiveOwner,SetInteractiveOwner] = useState(null);

  const [allTokens, setAllTokens] = useState([]);

  const [viewUserAddress, setViewUserAddress] = useState('');

  async function fetchAllTokens() {
    var inputtokens = [];

    if (typeof window.ethereum !== 'undefined') {
      console.log('fetching all tokens');

      try {
        const data = await contract.tokenCounter(); //the maximum
        console.log('maximum counter: ', data);
        for (let i = 0; i < data; i++) {
          //temporary just going to use this increment
          inputtokens.push(i);
        }
        console.log(inputtokens);
      } catch (err) {
        console.log('fetch all token error: ', err);
      }
    } else {
      console.log('ethereum api not detected');
    }

    //append mversetokens

    inputtokens=await IncludeMverseTokens(inputtokens);
    
    return inputtokens;
  }

  function SetupRandomToken() {
    let randomindex = getRandomInt(allTokens.length); // between 0 to max-1
    setToken(allTokens[randomindex]);
  }

  async function fetchAllTokensAndLoadPage() {
    console.log('gallery index use effect');
    let result = await fetchAllTokens();

    setAllTokens(result);
  }

  async function refreshAndViewTokenPage(tokenid) {
    await fetchAllTokensAndLoadPage();
    await viewTokenPage(tokenid);
  }
  async function viewTokenPage(tokenid) {
    try {
      
    if(!AutoDetectNetworkPage()){return;}
      console.log('viewing new token', tokenid);

      setToken(tokenid);
      setView(ONE_TOKEN);
    } catch (error) {
      console.error(error);
    }
  }
  async function viewInteractivePage(tokenin,interactiveownerin) {
    try {
      
    if(!AutoDetectNetworkPage()){return;}
      //console.log('viewing new token interactive from url: ', url);
      SetInteractiveOwner(interactiveownerin);
      setToken(tokenin);
      //setInteractiveURL(url);
      setView(INTERACTIVE);
    } catch (error) {
      console.error(error);
    }
  }

  async function viewUserPage(addr) {
    if(!AutoDetectNetworkPage()){return;}

    setViewUserAddress(addr); //set address then swap view
    setView(USER_TOKENS);
  }

  function AutoDetectNetworkPage(){

    if(window.ethereum!=null&&correctChain(dappState.chainId)){
        return true;
    }else{
      //set view and return false;
      setView(CORRECT_NETWORK_WINDOW);
      return false;
    }
    
  }

  function SimpleDetectNetworkPage(){

    if(window.ethereum!=null&&correctChain(dappState.chainId)){
        return true;
    }else{
      //set view and return false;
      return false;
    }
    
  }

  function RollInteractiveFn(){
    
    SetInteractiveOwner(null);
    SetupRandomToken();
    setView(INTERACTIVE);
  }


  function onClickOptionItem(item) {

    if(item!=CORRECT_NETWORK_WINDOW
        &&
        item!=MINT_TOKEN
      ){
      if(!AutoDetectNetworkPage()){return;}
    }

    switch (item) {
      case CORRECT_NETWORK_WINDOW:
        setView(CORRECT_NETWORK_WINDOW);
        break;
      case TOKEN_GALLERY:
        setView(TOKEN_GALLERY);
        break;
      case ONE_TOKEN:
        setView(ONE_TOKEN);
        break;
      case RANDOM_INTERACTIVE:
        //setup a random token, then pass into interactive
        RollInteractiveFn();
        break;
      case RANDOM_TOKEN:
        //setup a random token, then pass into interactive
        SetupRandomToken();
        setView(ONE_TOKEN);
        break;
      case MINT_TOKEN:
        setView(MINT_TOKEN);
        break;
      case MY_TOKENS:
        setView(MY_TOKENS);
        break;
      case 'Project Info':
      case 'Contract':
      case 'About':
        onClickMenuItem('Help');
        break;
      default:
    }
  }

  //#endregion


  useEffect(() => {
    fetchAllTokensAndLoadPage();

    //also get the location of url
    //if there is tokenid here, just load the token page
    try {

      if(dappState.externalstatread){return;}

      console.log('print url href',window.location.href);
      console.log('print url path',window.location.pathname);

      let str=window.location.pathname;
      str = str.substring(1);

      let theview=EXTERNAL_LINK;

      //detect interactive
      if(str.charAt(0)=='i'){
        str = str.substring(1);
      }

      if(str!=''){
          let tid=parseInt(str); 
          setToken(tid);
          setView(theview);
      }
      
    }
    catch(err) {
      console.log('path error');
    }
    
    setDappState({...dappState,externalstatread:true});

  }, []);

  //#region UI Control



  return (
    
    /* 
    <ThemeProvider theme={modernDark}>
       */
    <ThemeProvider theme={modernDark}>

      <Div>
        <section className="np__toolbar" >
          <WindowDropDowns
            items={dropDownData}
            onClickItem={onClickOptionItem}
          />
        </section>

        <Panel
          variant="well"
          className='panel'
          style={{

            display:'flex',/* simulationlock */
/* 
            display: 'flex', */

            padding: '1rem',
            overflow: 'auto',
            background: '#202127',
            margin:'2px',
            justifyContent:'center',
            overflow:'auto',
          alignItems:'flex-start',
          height:'100%'
          }}
        >
            {view === MINT_TOKEN && (
                <MintToken allTokens={allTokens} 
                viewTokenFn={refreshAndViewTokenPage} rollrandom={RollInteractiveFn}></MintToken>
            )}
            {view === ONE_TOKEN && (
                <OneToken
                  token={token}
                  allTokens={allTokens}
                  baseURI={baseURI}
                  viewTokenFn={viewTokenPage}
                  viewInteractiveFn={viewInteractivePage}
                  viewUserTokensFn={viewUserPage}
                ></OneToken>
            )}

            {view === TOKEN_GALLERY && (
              <div>
                <TokenGallery
                  allTokens={allTokens}
                  baseURI={baseURI}
                  viewTokenFn={viewTokenPage}
                ></TokenGallery>
              </div>
            )}
 {/*            {view === INTERACTIVE && (
                <Interactive viewTokenFn={viewTokenPage} allTokens={allTokens} url={interactiveURL} token={token} userpreviewmodein={true} ownerinput={interactiveOwner}></Interactive>
            )}
 */}

{view === INTERACTIVE && (
                <ExtraInteractive viewTokenFn={viewTokenPage} allTokens={allTokens} url={interactiveURL} token={token} userpreviewmodein={true} ownerinput={interactiveOwner}></ExtraInteractive>
            )}



            {view === MY_TOKENS && (
              <div>
                <UserTokens
                  user={address}
                  baseURI={baseURI}
                  viewTokenFn={viewTokenPage}
                ></UserTokens>
              </div>
            )}

{view === USER_TOKENS && (
              <div>
                <UserTokens
                  user={viewUserAddress}
                  baseURI={baseURI}
                  viewTokenFn={viewTokenPage}
                ></UserTokens>
              </div>
            )}

{view === CORRECT_NETWORK_WINDOW && (
              <div>
                <CorrectNetworkWindow
                ></CorrectNetworkWindow>
              </div>
            )}

{view === EXTERNAL_LINK  && (
              <div>
                <ExternalLink
                  token={token}
                  viewTokenFn={viewTokenPage}
                  viewInteractiveFn={viewInteractivePage}
                ></ExternalLink>
              </div>
            )}



        </Panel>
      </Div>
    </ThemeProvider>
  );
}


const Div = styled.div`
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: stretch;
  background: '#202127'
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
    
  }

  .panel{
    background:url(${bg})!important;    
    background-size: cover!important;
    background-position: center;
  }
`;

const StyledTextarea = styled.textarea`
  flex: auto;
  outline: none;
  font-family: 'Lucida Console', monospace;
  font-size: 13px;
  line-height: 14px;
  resize: none;
  padding: 2px;
  ${props => (props.wordWrap ? '' : 'white-space: nowrap; overflow-x: scroll;')}
  overflow-y: scroll;
  border: 1px solid #96abff;
`;
