import styled from 'styled-components';
import axios from 'axios';
import { autourl, animationurl, PLAYER } from 'lib/lib.js';

import { createGlobalStyle, ThemeProvider } from 'styled-components';

import original from 'react95/dist/themes/original';
import modernDark from 'react95/dist/themes/modernDark';
// original Windows95 font (optionally)
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

import {
  MAX_TOKEN,
  getRandomInt,
  BASE_URI,
  ONE_TOKEN,
  TOKEN_GALLERY,
  INTERACTIVE,
  MINT_TOKEN,
  MY_TOKENS,
  USER_TOKENS,
  RANDOM_INTERACTIVE,
  RANDOM_TOKEN, CORRECT_NETWORK_WINDOW, correctChain, EXTERNAL_LINK,
  MVERSE_TOKEN, CONTRACT_ADDR, MINT_PRICE_DEFAULT, PLAYERNAME, PLAYERNAMENFT, PLAYERNAMES, PLAYERNAMENFTS, PLAYERNAME_NFT,XenoRuleURL,XenoTiersURL,trailerhost
} from 'lib/lib.js';

import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';

import {
  styleReset,
  Button,
  TextField,
  WindowHeader,
  WindowContent,
  List,
  ListItem,
  Divider,
  Cutout,
  Toolbar,
  Panel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeadCell,
  TableDataCell,
  Window,
} from 'react95';

const Home = () => {

  const [dappState, setDappState] = useGlobal(); //entire dappstate

  const contract = dappState.contract;
  const address = dappState.address;

  const [tokensLeft, SetTokensLeft] = useState(-1);

  //fetches all tokens that are minted at the moment
  async function fetchTokensLeft() {
    var inputtokens = [];

    if (typeof window.ethereum !== 'undefined') {
      console.log('fetching tokens left');

      try {
        const data = await contract.tokenCounter(); //the maximum
        //if 8000 then maximum

        SetTokensLeft(MAX_TOKEN - data);

      } catch (err) {
        console.log('fetch all token error: ', err);
      }
    } else {
      console.log('ethereum api not detected');
    }

    return inputtokens;
  }

  useEffect(() => {
    fetchTokensLeft();
  }, [address]);

  return (
    <ThemeProvider theme={modernDark}>
    <Div>
      <h2>About </h2>
      <Divider />
      <div>
      <span className='bold'>Xeno Infinity is designed for players & visitors of the MVM & Xeno metaverse experiences</span>, reimagined for everyone. read current version project notes <a className='specialtext' href="https://vmcreations.gitbook.io/multiverse-vm-and-xeno-infinity-platform/brainstorm/thoughts-on-experience-creation">here</a><br />
        <br />
      
      Enter the 3D open metaverse with any of your NFTs via {PLAYERNAMENFT}, the possibilities are endless, made possible with the interopability of the Ethereum blockchain and {PLAYERNAMENFT} tech.
        <br /><br />

        The first open metaverse experience to support all your NFTs, bring your CryptoPunks/APE/KONGZ/CoolCats and more into a 3D metaverse, an <span className='bold'>open metaverse future.</span>
        <br />

      </div>

      <div className='vidsection'>
        <div className='vidDivA vidDiv'>
          <video className='thevid'  src={trailerhost+"trailerA.mp4"}
            autoPlay loop muted playsInline controls preload="auto" >
          </video>
        </div>

        <div className='vidDivB vidDiv'>
          <video className='thevid'  src={trailerhost+"trailerB.mp4"}
            autoPlay loop muted playsInline controls preload="auto" >
          </video>
        </div>
      </div>

      <div>
        {/* 
        <br /><br /> */}
        Compatible with most NFTs RIGHT NOW, be first to experience what an open metaverse is, push the boundaries of what NFTs are capable of with {PLAYERNAMENFT}, each and every {PLAYERNAME} comes with its own totally unique features, and each of them has the ability to load your NFT into its dynamic screen, opening the door to endless possibilities.
        <br /><br />

      </div>

      <h2>Interactive Mode</h2>
      <Divider />

      <div>
        <span className='bold'>Load NFT</span>: Select one of your NFT collections from the drop down list, click one of your NFTs and then click <span className='bold'>'Load NFT' to load it into the {PLAYERNAMENFT} Head Display, set it as your {PLAYERNAMENFT} in-verse identity</span><br />
        WASD: control movement<br />
        5: Toggle Λ-Effector<br />
        Hold Shift: sprint<br />
      </div>


      <h2>{PLAYERNAMENFT} x MVM</h2>
      <Divider />

      <div>
        Both the XENO x MVM UPDATE-X metaverse experience & Exploration Demo is already online TODAY, hop into it with your {PLAYERNAME_NFT} and experience the endless possibilities.
      </div>

      <h2>About {PLAYERNAMENFT}</h2>
      <Divider />

      <div>
        {PLAYERNAMENFT} was created after countless iterations and experiments to build a truly interopable open metaverse experience, and an open interoperable digital identity that works with all your NFTs, and it's ready today!
        <br />
        <br />
        {PLAYERNAMENFT} is designed for visitors & players within the MVM & Xeno ecosystem, and is designed so it can <span className='bold'>be used by players & visitors.</span>
        <br />
        <br />

        {PLAYERNAMENFT} works with MVM project from <span className='bold'>day one right here on this website</span>, with <span className='bold'>special abilities and fun in-verse buffs</span> that are enjoyed by {PLAYERNAMENFT} owners, MVM owners with {PLAYERNAMENFT} have extra fun buffs for their Simulations experience on the website, the first <span className='bold'>'𝕄𝕖𝕥𝕒𝕧𝕖𝕣𝕤𝕖 𝕏' experience🧪</span> (exploration chapter) can be experienced by downloading the desktop apps on the website ⚡🏎️, read more details in the project notes.

        <br />
        <br />
        All NFTs minted are licensed under the NFT License @ https://www.nftlicense.org/, token uri metadata includes both decentralized versions and hosted versions of the NFT content to guarantee performance while using the NFT, and ensuring persistence of NFT data via IPFS. Xeno Infinity NFTs and MultiverseVM NFTs are NOT investment vehicles, they are collectible digital interactive art pieces that also function as Non-Fungible Tokens for you to collect. They were created as art pieces intended for people to enjoy by collecting and playing, not as a financial instrument.
        <br/>
        <br/>
        Extra data including the 3D interactive's HD + IPFS version will be added using the 'add multi uri' function after the initial minting process (this includes expansion packs in the future), metadata will be locked into IPFS 2 months after the initial minting process.
      </div>

      <div>
        <p>
          <strong>NFT Contract address:</strong>&nbsp;
          {CONTRACT_ADDR}
          <br />
          <strong>Collection Name:</strong> {PLAYERNAMENFT}
          <br />
        {/*   <strong>Price:</strong> {MINT_PRICE_DEFAULT} ETH<br /> */}
          <strong>Price:</strong> 0.08 ETH<br />
          <strong>Tokens:</strong> {MVERSE_TOKEN + MAX_TOKEN}<br />
          <strong>Mint Status:</strong> {tokensLeft == 0 ? 'Sold Out' : 'See Twitter/Discord'}<br />

          <strong>Wrapper Contract for Minting:</strong>&nbsp;
          0xaBd9F85030Df1Ec2A3117Fa12b42c73a0d6fdC5d
          <br />
          
          <strong>Wrapper Contract Details:</strong>&nbsp; <a className='graylink' href="https://vmcreations.notion.site/Xeno-Infinity-Mint-Details-83863540b2274077940501cf3b3a1603" target="_blank">
          Deployment Details on Wrapper Contract</a>
          <br />

        </p>
      </div>

      <h2>Links</h2>
      <Divider />

      <div className='thelinks'>
        <a href={"https://etherscan.io/token/" + CONTRACT_ADDR} target="_blank">NFT Etherscan</a><br />
        <a href="https://etherscan.io/address/0xaBd9F85030Df1Ec2A3117Fa12b42c73a0d6fdC5d" target="_blank">Wrapper Contract Etherscan</a><br />
        <a href="https://vmcreations.gitbook.io/multiverse-vm-and-xeno-infinity-platform/brainstorm/mvm+xeno-and-the-value-it-brings-to-owners">Whitepaper & Notes</a><br />

        <a href="https://twitter.com/MultiverseVM" target="_blank">Twitter</a><br />

        <a href="https://discord.gg/b7ZcEKBEsu">Discord</a>
        
        <br /><br />
{/*           <a href="https://opensea.io/collection/xenoinfinity">OS</a><br /><br /> */}

        <a href="https://youtu.be/wokZB8k-o50" target="_blank">Xeno Infinity Video</a>&nbsp;
        <a href="https://youtu.be/KeoUsgLoamM" target="_blank">Xeno Infinity Short Film</a>&nbsp;
        <a href="https://youtu.be/HnavYWGIo-M" target="_blank">Xeno Infinity Video 2</a><br />
{/*           <a href={XenoRuleURL} target="_blank">X-XENO Event Perks & Rules</a>&nbsp;
        <a href={XenoTiersURL} target="_blank">Xeno Infinity In-verse tiers (v1)</a><br /><br /> */}
        
        <a href="https://twitter.com/MultiverseVM" target="_blank">Twitter</a> &nbsp;
        <a href="https://twitter.com/0xRKY" target="_blank">0xRKY</a> &nbsp;
        <a href="https://twitter.com/0xCocoChan" target="_blank">0xCo̲c̲o̲ Dͩeͤvͮ 𝕫</a> &nbsp;
        <a href="https://youtu.be/vFcS080VYQ0" target="_blank">the devs daily ☕ for building</a><br />
      
      
      </div>
    </Div>
  </ThemeProvider>
  );
};

const Div = styled.div`

.specialtext{
  color:white!important;
}


line-height: 14px;
.vidsection{
  height: 220px;
  display: flex;
}

.thevid{
  width: 100%;
height: 100%;
max-height: 100%;
}

.vidDiv{    
background: black;
}

.thelinks a{
  color: #adadad;
}
.graylink{
  color: #adadad;
}

.bold{
  font-weight:bold;
  color:white;
}

  background: #202127;
  height: 100%;
  width: 100%;
  font-size: 11px;
  .item {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    font-size: 12px;
    width: 100%;
  }

  .item video {
    margin-bottom: 5px;
    object-fit: cover;
    width: 90%;
    height: 85%;
  }

  div {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  h2 {
    margin-bottom: 5px;
  }
`;

export default Home;
