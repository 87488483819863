import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import styled from 'styled-components';
// add child div to capture mouse event when not focused
import {
  trailerhost,youtubeurlC,YOUTUBEMODE
 } from 'lib/lib.js';

function Portal({ onClose, isFocus }) {
  const [dappState, setDappState] = useGlobal();

  const contract = dappState.contract;
  const address = dappState.address;

  return (
    <Div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <div className='vidDiv'>
      

        {(!YOUTUBEMODE) && (
     <video className='thevid' src={trailerhost+"trailerC.mp4"}
     autoPlay loop muted playsInline controls preload="auto" >
     </video>
          )}
          {(YOUTUBEMODE) && (

<iframe id="ytplayer" className='youtubevid' type="text/html" 
src={youtubeurlC}
frameborder="0" allowfullscreen></iframe>
          )}

      </div>



    </Div>
  );
}

const Div = styled.div`
width: '100%';
height: '100%';
position: 'relative';

.youtubevid{
  width: 100%;
  height: 100%;
}
.thevid{
      width: 100%;
  height: 100%;
  max-height: 100%;
}

.vidDiv{    width: 100%;
  max-height: 100%;
  height: 100%;
  background: black;
}

`;

export default Portal;
